import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePanByAdmin } from '../../../action/admin/Index';
import { connect } from 'react-redux';
// import Codes from '../code/Codes';

const PanUpdate = ({ updatePanByAdmin }) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [panData, setPanData] = useState({
        id: state?._id || '',
        loading: state?.loading || '',
        aocode: state?.aocode || '',
        ao: state?.ao || '',
        range: state?.range || '',
        aonumber: state?.aonumber || '',
        application: state?.application || '',
        old_pan: state?.old_pan || '',
        category: state?.category || 'INDIVIDUAL',
        applicant: state?.applicant || '',
        firstname: state?.firstname || '',
        middlename: state?.middlename || '',
        lastname: state?.lastname || '',
        ffirstname: state?.ffirstname || '',
        fmiddlename: state?.fmiddlename || '',
        flastname: state?.flastname || '',
        dob: state?.dob || '',
        contect_number: state?.contect_number || '',
        email: state?.email || '',
        proofid: state?.proofid || 'AADHAAR CARD',
        proofadd: state?.proofadd || 'AADHAAR CARD',
        proofdob: state?.proofdob || 'AADHAAR CARD',
        adhar_number: state?.adhar_number || '',
        address_f: state?.address_f || '',
        address_v: state?.address_v || '',
        address_p: state?.address_p || '',
        address_divi: state?.address_divi || '',
        address_d: state?.address_d || '',
        state: state?.state || '',
        pin_code: state?.pin_code || '',
        invalidImage: state?.invalidImage || '',
        invalidSig: state?.invalidSig || '',
        invalidPdf: state?.invalidPdf || '',
        imagePreviewUrl: state?.imagePreviewUrl || '',
        sigPreviewUrl: state?.sigPreviewUrl || '',
        pdfPreviewUrl: state?.pdfPreviewUrl || '',
        image: state?.image || '',
        pdf: state?.pdf || '',
        sig: state?.sig || '',
        panStatus: state?.panStatus || '',
        reasonOfReject: state?.reasonOfReject || '',
        panmsg: state?.panmsg || '',
        msg: []
    })
    if (state?.id) {
        setPanData({ ...panData, state })
    }
    const changeHandler = (e) => {
        setPanData({ ...panData, [e.target.name]: e.target.value.toUpperCase() })
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        const validFirstname = new RegExp('^([a-zA-Z]){3,}$');
        const validAddress = new RegExp('^([a-zA-Z ]){3,}$');
        const mobile = new RegExp('^([0-9]).{9}$');
        const adhar_number = new RegExp('^([0-9]).{11}$');
        const regexPan = /^\b[A-Za-z]{5}\d{4}[A-Za-z]{1}\b$/i;
        const pin_code = new RegExp('^([0-9]).{5}$');
        const dob = new RegExp("^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\\d\\d$")
        if (e.target.name == 'application') {
            if (e.target.value == "CORECATION") {
                document.getElementById("old_pan").style.visibility = "visible";
                document.getElementById("oldPan").style.visibility = "visible";
            } else {
                document.getElementById("oldPan").style.visibility = "hidden";
                document.getElementById("old_pan").style.visibility = "hidden";
            }
        } else if (e.target.name == 'old_pan') {
            if (regexPan.test(e.target.value)) {
                document.getElementById('old_pan').className = 'form-control rounded-0';
            } else {
                document.getElementById('old_pan').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'firstname') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('firstname').className = 'form-control rounded-0';
            } else {
                document.getElementById('firstname').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'middlename') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('middlename').className = 'form-control rounded-0';
            } else {
                document.getElementById('middlename').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'lastname') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('lastname').className = 'form-control rounded-0';
            } else {
                document.getElementById('lastname').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'lastname') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('lastname').className = 'form-control rounded-0';
            } else {
                document.getElementById('lastname').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'ffirstname') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('ffirstname').className = 'form-control rounded-0';
            } else {
                document.getElementById('ffirstname').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'fmiddlename') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('fmiddlename').className = 'form-control rounded-0';
            } else {
                document.getElementById('fmiddlename').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'flastname') {
            if (validFirstname.test(e.target.value)) {
                document.getElementById('flastname').className = 'form-control rounded-0';
            } else {
                document.getElementById('flastname').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'dob') {
            if (dob.test(e.target.value)) {
                document.getElementById('dob').className = 'form-control rounded-0';
            } else {
                document.getElementById('dob').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'contect_number') {
            if (mobile.test(e.target.value)) {
                document.getElementById('contect_number').className = 'form-control rounded-0';
            } else {
                document.getElementById('contect_number').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'adhar_number') {
            if (adhar_number.test(e.target.value)) {
                document.getElementById('adhar_number').className = 'form-control rounded-0';
            } else {
                document.getElementById('adhar_number').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'email') {
            if (validEmail.test(e.target.value)) {
                document.getElementById('email').className = 'form-control rounded-0';
            } else {
                document.getElementById('email').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'pin_code') {
            if (pin_code.test(e.target.value)) {
                document.getElementById('pin_code').className = 'form-control rounded-0';
            } else {
                document.getElementById('pin_code').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'address_f') {
            if (validAddress.test(e.target.value)) {
                document.getElementById('address_f').className = 'form-control rounded-0';
            } else {
                document.getElementById('address_f').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'address_v') {
            if (validAddress.test(e.target.value)) {
                document.getElementById('address_v').className = 'form-control rounded-0';
            } else {
                document.getElementById('address_v').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'address_p') {
            if (validAddress.test(e.target.value)) {
                document.getElementById('address_p').className = 'form-control rounded-0';
            } else {
                document.getElementById('address_p').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'address_divi') {
            if (validAddress.test(e.target.value)) {
                document.getElementById('address_divi').className = 'form-control rounded-0';
            } else {
                document.getElementById('address_divi').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'address_d') {
            if (validAddress.test(e.target.value)) {
                document.getElementById('address_d').className = 'form-control rounded-0';
            } else {
                document.getElementById('address_d').className = 'form-control rounded-0 error';
            }
        } else if (e.target.name == 'gender') {
            setPanData({ ...panData, ['gender']: e.target.value.toUpperCase() })
        }
        if (e.target.className == 'error') {
            const submitBtn = document.getElementById('submit-btn');
            submitBtn.disabled = true;
        } else {
            const submitBtn = document.getElementById('submit-btn');
            submitBtn.disabled = false;
        }
    }
    const updateState = (calculation) => {
        if (calculation) {
            setPanData({
                ...panData,
                aocode: calculation.areacode,
                ao: calculation.aotype,
                range: calculation.rangecode,
                aonumber: calculation.aonumber
            })
        }
    }
    var array1 = [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
        [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
        [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
        [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
        [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
        [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
        [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
        [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
        [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]
    ];

    var array2 = [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
        [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
        [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
        [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
        [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
        [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
        [7, 0, 4, 6, 9, 1, 3, 2, 5, 8]
    ];
    function invArray(array) {
        if (Object.prototype.toString.call(array) === "[object Number]") {
            array = String(array);
        }
        if (Object.prototype.toString.call(array) === "[object String]") {
            array = array.split("").map(Number);
        }
        return array.reverse();
    }

    function validateAadhaar(array) {
        var c = 0;
        var invertedArray = invArray(array);
        for (var i = 0; i < invertedArray.length; i++) {
            c = array1[c][array2[(i % 8)][invertedArray[i]]];
        }
        return (c === 0);
    }
    const AdharOnBlur = (e) => {
        if (!validateAadhaar(e.target.value)) {
            alert(e.target.value + ' is not a valid AADHAAR number.');
            e.target.value = '';
        } else {
            setPanData({ ...panData, [e.target.name]: e.target.value })
        }

    }
    const startRendSplChar = (x) => {
        if (x.length > 0) {
            var specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
            for (let i = 0; i < specialChars.length; i++) {
                if (x.endsWith(specialChars[i]) || x.startsWith(specialChars[i])) {
                    return true;
                }
            }

            var stringArray = ["ABCD", "ASDF", "REJECT", "AADHAAR", "AADHAR", "AGENCY", "ARTS", "ASSOCIATES", "BANK", "BAZAR", "BOOK", "BOOKER", "BUDGET", "CAFÉ", "CAFFE", "CARD", "CARE", "CENTAR", "CENTER", "CENTRE", "CLUB", "COLLEGE", "COMMON", "COMMUNICATION", "COMMUNICATIONS", "COMPANY", "COMPUTER", "COMPUTERS", "COMPUTRONIX", "CONSULTANCY", "CONSULTANTS", "CORNER", "CSC", "CSP", "CYBER", "DAHAGWAN", "DAHGAWAN", "DAILY", "DAIRY", "DIGITAL", "DIGITALS", "ELECTRONICS", "ENTERPRISE", "ENTERPRISES", "FARM", "FOREST", "GALLERY", "GARMENTS", "GLOBAL", "GRAPHICS", "GROUP", "HEALTH", "HELTH", "HITECH", "INDUSTRIES", "INSTITUTE", "INTERNET", "JANSEVA", "JANSUBIDHA", "JANHITSEVA", "JANSEWA", "JANSUVIDHA", "KENDER", "KENDRA", "KIOSK", "LABOUR", "LADIES", "LAND", "LIBRARY", "LIMITED", "LTD", "MANDIR", "MANPOWER", "MARKET", "MEDICAL", "MEESEVA", "MEEMSA", "MEESEVA", "MOBILE", "MONEY", "MULTI", "NSDL", "PAN", "PANCENTRE", "PHOTO", "PHOTOSTATE", "POINT", "PORSA", "PRESS", "PRIVATE", "PUBLIC", "PUBLISHING", "PVT", "RURAL", "SALES", "SCHOOL", "SECURITIES", "SECURITY", "SERVICE", "SERVICES", "SEVA", "SEWA", "SHOP", "SHOPEE", "SHREE", "SKYNET", "SOLUTION", "SOLUTIONS", "STORE", "STUDIO", "SUVIDHA", "SWEET", "TAX", "TECHNOLOGY", "TELECOM", "TRADERS", "TRANSFER", "TRAVEL", "TRAVELS", "TYPE", "WEB", "WORK", "WORLD", "XEROX", "ZEROX"];
            for (let j = 0; j < stringArray.length; j++) {
                if (x.includes(stringArray[j])) {
                    return true;
                }
            }

            var stringArray1 = ["C/O", "CO", "M/A", "M/S", "STEEL CITY"];
            for (let k = 0; k < stringArray1.length; k++) {
                if (x.startsWith(stringArray1[k])) {
                    return true;
                }
            }

            var stringArray2 = ["ADD", "ADDRESS", "AGA", "HNO", "HNO0", "HNO00", "HNO-0", "H.N.-000", "H.NO.00", "H.NO.0", "H.NO.00", "H.NO-0", "H/N0", "HN0", "HN00", "HN0", "HN-0", "HN00", "HN-00", "HNO", "HNO00", "HOUSE", "HOUSENO", "HOUSENO0", "HOUSENO00", "HOUSENO.", "HOUSENO.-0", "HOUSENO.00", "HOUSENO-0", "HOUSENO-00", "I", "II", "N", "NA", "NH", "N/A", "NA", "NEAR", "NEJ", "NET", "NEW", "NIL", "NILL", "NO", "NO0", "NO00", "NO.0", "NOAN", "NOTAPPLICABLE", "NULL", "O", "OFFICE", "OFFICEOF", "OO", "OOO", "OOOO", "OR", "PKS", "SAI", "X", "XX", "XXX", "XXXX", "ZZ"];
            for (let l = 0; l < stringArray2.length; l++) {
                if (x == stringArray2[l]) {
                    return true;
                }
            }

            if (x.length < 2) {
                return true;
            }
        }
        return false;
    }
    const fileChange = (e) => {
        // setPanData({ ...panData, [e.target.name]: e.target.files[0] })
        let reader = new FileReader();
        if (e.target.name == 'image') {
            reader.onloadend = () => {
                setPanData({ ...panData, ['imagePreviewUrl']: reader.result, ['image']: e.target.files[0] });
            }
        } else if (e.target.name == 'sig') {
            reader.onloadend = () => {
                setPanData({ ...panData, ['sigPreviewUrl']: reader.result, ['sig']: e.target.files[0] });
            }
        } else if (e.target.name == 'pdf') {
            reader.onloadend = () => {
                setPanData({ ...panData, ['pdfPreviewUrl']: reader.result, ['pdf']: e.target.files[0] });
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if (panData.panStatus == '' || panData.panStatus == undefined) {
            alert('Please select for verify')
            return false;
        }
        updatePanByAdmin(panData, navigate)

    }
    return (
        <div className="container mt-5">
            <form onSubmit={ submitHandler }>
                <div className="row mx-3">
                    {/* <Codes update={ updateState } /> */ }
                    <div className="card my-5">
                        <div className="card-header bg-secondary text-light">
                            General Information -
                        </div>
                        <div className="card-body">
                            <div className="mt-2 row">
                                <label htmlFor="aocode" className="col-sm-3 col-form-label">AO Codes</label>
                                <div className="col-sm-2">
                                    <input type="text" placeholder='AO Code' onChange={ (e) => changeHandler(e) } name="aocode" id="aocode" readOnly value={ panData.aocode } className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-2">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name="ao" id="ao" readOnly value={ panData.ao } placeholder='AO Type' className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-2">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name="range" id="range" readOnly value={ panData.range } className="form-control rounded-0" placeholder='Range Code' />
                                </div>
                                <div className="col-sm-2">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name="aonumber" id="aonumber" readOnly value={ panData.aonumber } className="form-control rounded-0" placeholder='AO number' />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="application" className="col-sm-3 col-form-label">Application Type</label>
                                <div className="col-sm-4">
                                    <select className="form-select rounded-0" name="application" id="application" value={ panData.application } onChange={ (e) => changeHandler(e) } aria-label="Default select example">
                                        <option >-- select --</option>
                                        <option selected value="NEW PAN">New Application</option>
                                        <option value="CORECATION">Correction/Reprint</option>
                                    </select>
                                </div>
                                {
                                    panData.application == 'CORECATION' ? <div className="col-sm-4">
                                        <input type="text" name="old_pan" onChange={ (e) => changeHandler(e) } value={ panData.old_pan } id="old_pan" placeholder='Old Pan number' className="form-control rounded-0" />
                                    </div> : null
                                }
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="application" className="col-sm-3 col-form-label">Category of Applicant</label>
                                <div className="col-sm-4">
                                    <select name="category" onChange={ (e) => changeHandler(e) } value={ panData.category } className="form-select rounded-0" aria-label="Default select example" >
                                        <option >-- select --</option>
                                        <option selected value="INDIVIDUAL">Individual</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="application" className="col-sm-3 col-form-label">Applicant's title</label>
                                <div className="col-sm-4">
                                    <select name="applicant" id="applicant" onChange={ (e) => changeHandler(e) }
                                        value={ panData.applicant } className="form-select rounded-0" aria-label="Default select example">
                                        <option >-- select --</option>
                                        <option selected value="MR">MR</option>
                                        <option value="MRS">MRS</option>
                                        <option value="KUMARI">KUMARI</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="cardholder" className="col-sm-3 col-form-label">Applicant Name</label>
                                <div className="col-sm-3">
                                    <input type="text" name="firstname" id="firstname" placeholder="First Name" autoComplete="off" value={ panData.firstname } onChange={ (e) => changeHandler(e) } className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-3">
                                    <input type="text" name="middlename" id="middlename" placeholder="Middle Name" autoComplete="off" value={ panData.middlename } onChange={ (e) => changeHandler(e) } className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control rounded-0" name="lastname" id="lastname" placeholder="Last Name" autoComplete="off" value={ panData.lastname } onChange={ (e) => changeHandler(e) } />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Father's Name</label>
                                <div className="col-sm-3">
                                    <input type="text" name="ffirstname" id="ffirstname" placeholder="First Name" autoComplete="off" value={ panData.ffirstname } onChange={ (e) => changeHandler(e) } className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-3">
                                    <input type="text" name="fmiddlename" id="fmiddlename" placeholder="Middle Name" autoComplete="off" value={ panData.fmiddlename } onChange={ (e) => changeHandler(e) } className="form-control rounded-0" />
                                </div>
                                <div className="col-sm-3">
                                    <input type="text" className="form-control rounded-0" id='flastname' name="flastname" placeholder="Last Name" autoComplete="off" value={ panData.flastname } onChange={ (e) => changeHandler(e) } />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Name on Card</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='card holder name' value={ `${panData.firstname} ${panData.middlename} ${panData.lastname}` } className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Date of Birth</label>
                                <div className="col-sm-4">
                                    <input type="text" placeholder='Date of birth' onChange={ (e) => {
                                        e.target.value = e.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2').replace(/[^\d\/]/g, '')
                                        changeHandler(e)
                                    } } value={ panData.dob } name="dob" id="dob" className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Contact Details</label>
                                <div className="col-sm-4">
                                    <input type="text" onChange={ (e) => changeHandler(e) } value={ panData.contect_number } name="contect_number" id="contect_number" placeholder='Mobile number' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">E-mail ID</label>
                                <div className="col-sm-4">
                                    <input type="text" onChange={ (e) => changeHandler(e) } value={ panData.email } name="email" id="email" placeholder='E-mail ID' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Gender</label>
                                <div className="col-sm-4">
                                    <input type="text" placeholder='Gender' name="gender" id="gender" value={ panData.applicant === "MR" ? "MALE" : "FEMALE" } onChange={ (e) => changeHandler(e) } className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Aadhar No.</label>
                                <div className="col-sm-4">
                                    <input type="text" placeholder='12 digit AADHAR' onChange={ (e) => changeHandler(e) } value={ panData.adhar_number } onBlur={ (e) => AdharOnBlur(e) } name="adhar_number" id="adhar_number" className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="card-header bg-secondary text-light mt-1">
                                Address Details -
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Father/Husband/Guardian Name:</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='Father/Husband/Guardian Name' onBlur={ (e) => {
                                        if (startRendSplChar(e.target.value.toUpperCase())) {
                                            alert(e.target.value + '\nis not a valid address line.');
                                            e.target.value = '';
                                            e.target.focus();
                                        }
                                    } } onChange={ (e) => changeHandler(e) } value={ panData.address_f } name="address_f" id="address_f" className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Premises/Building/Village:</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='Premises/Building/Village' onBlur={ (e) => {
                                        if (startRendSplChar(e.target.value.toUpperCase())) {
                                            alert(e.target.value + '\nis not a valid address line.');
                                            e.target.value = '';
                                            e.target.focus();
                                        }
                                    } } onChange={ (e) => changeHandler(e) } value={ panData.address_v } name='address_v' id='address_v' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Road/Street/Lane/Post Office:</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='Road/Street/Lane/Post Office' onBlur={ (e) => {
                                        if (startRendSplChar(e.target.value.toUpperCase())) {
                                            alert(e.target.value + '\nis not a valid address line.');
                                            e.target.value = '';
                                            e.target.focus();
                                        }
                                    } } onChange={ (e) => changeHandler(e) } value={ panData.address_p } name='address_p' id='address_p' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Area/Taluka/Sub-division:</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='Area/Taluka/Sub-division' onBlur={ (e) => {
                                        if (startRendSplChar(e.target.value.toUpperCase())) {
                                            alert(e.target.value + '\nis not a valid address line.');
                                            e.target.value = '';
                                            e.target.focus();
                                        }
                                    } } onChange={ (e) => changeHandler(e) } name='address_divi' id='address_divi' value={ panData.address_divi } className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">Town/District:</label>
                                <div className="col-sm-9">
                                    <input type="text" placeholder='Town/District' onBlur={ (e) => {
                                        if (startRendSplChar(e.target.value.toUpperCase())) {
                                            alert(e.target.value + '\nis not a valid address line.');
                                            e.target.value = '';
                                            e.target.focus();
                                        }
                                    } } onChange={ (e) => changeHandler(e) } value={ panData.address_d } name="address_d" id='address_d' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="mt-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">State/Union Territory:</label>
                                <div className="col-sm-9">
                                    <select name="state" className="form-select rounded-0" id="state" onChange={ (e) => changeHandler(e) } value={ panData.state }>
                                        <option>Please Select</option>
                                        <option value="ANDAMAN AND NICOBAR ISLANDS">ANDAMAN AND NICOBAR ISLANDS</option>
                                        <option value="ANDHRA PRADESH">ANDHRA PRADESH</option>
                                        <option value="ARUNACHAL PRADESH">ARUNACHAL PRADESH</option>
                                        <option value="ASSAM">ASSAM</option>
                                        <option value="BIHAR">BIHAR</option>
                                        <option value="CHANDIGARH">CHANDIGARH</option>
                                        <option value="CHHATTISGARH">CHHATISHGARH</option>
                                        <option value="DADRA & NAGAR HAVELI">DADRA &amp; NAGAR HAVELI</option>
                                        <option value="DAMAN & DIU">DAMAN &amp; DIU</option>
                                        <option value="DELHI">DELHI</option>
                                        <option value="GOA">GOA</option>
                                        <option value="GUJARAT">GUJARAT</option>
                                        <option value="HARYANA">HARYANA</option>
                                        <option value="HIMACHAL PRADESH">HIMACHAL PRADESH</option>
                                        <option value="JAMMU AND KASHMIR">JAMMU &amp; KASHMIR</option>
                                        <option value="JHARKHAND">JHARKHAND</option>
                                        <option value="KARNATAKA">KARNATAKA</option>
                                        <option value="KERALA">KERALA</option>
                                        <option value="LADAKH">LADAKH</option>
                                        <option value="LAKHSWADEEP">LAKHSWADEEP</option>
                                        <option value="MADHYA PRADESH">MADHYA PRADESH</option>
                                        <option value="MAHARASHTRA">MAHARASHTRA</option>
                                        <option value="MANIPUR">MANIPUR</option>
                                        <option value="MEGHALAYA">MEGHALAYA</option>
                                        <option value="MIZORAM">MIZORAM</option>
                                        <option value="NAGALAND">NAGALAND</option>
                                        <option value="ODISHA">ODISHA</option>
                                        <option value="OUTSIDE INDIA">OUTSIDE INDIA</option>
                                        <option value="PONDICHERRY">PONDICHERRY</option>
                                        <option value="PUNJAB">PUNJAB</option>
                                        <option value="RAJASTHAN">RAJASTHAN</option>
                                        <option value="SIKKIM">SIKKIM</option>
                                        <option value="TAMIL NADU">TAMILNADU</option>
                                        <option value="TELANGANA">TELANGANA</option>
                                        <option value="TRIPURA">TRIPURA</option>
                                        <option value="UTTAR PRADESH">UTTAR PRADESH</option>
                                        <option value="UTTARAKHAND">UTTARAKHAND</option>
                                        <option value="WEST BENGAL">WEST BENGAL</option>
                                    </select>
                                </div>
                            </div>
                            <div className="my-1 row">
                                <label htmlFor="father details" className="col-sm-3 col-form-label">PIN:</label>
                                <div className="col-sm-9">
                                    <input type="text" onChange={ (e) => changeHandler(e) } value={ panData.pin_code } name="pin_code" id="pin_code" placeholder='PIN' className="form-control rounded-0" />
                                </div>
                            </div>
                            <div className="card-header bg-secondary text-light">
                                Documents Details -
                            </div>
                            <div className="mt-1 row">
                                <div className="col-md-6">
                                    { panData.image ? <embed src={ `/panfiles/${panData.image}` } type="application/pdf" style={ { width: '100%', height: '300px' } } /> : null }
                                </div>
                                <div className="col-md-6">
                                    { panData.sig ? <embed src={ `/panfiles/${panData.sig}` } type="application/pdf" style={ { width: '100%' } } /> : null }
                                </div>
                            </div>
                            <div className="row">
                                <div className="my-5 text-center">
                                    { panData.pdf ? <embed src={ `/panfiles/${panData.pdf}` } type="application/pdf" style={ { width: '100%', height: '500px' } } /> : null }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <label htmlFor="Status">Status</label>
                            <select name='panStatus' value={ panData.panStatus } onChange={ (e) => changeHandler(e) } className="form-select" aria-label="Default select example">
                                <option selected value={ '' }>-- Selete --</option>
                                <option value="TRUE">Verify</option>
                                <option value="REJECT">Reject</option>
                                <option value="MAKENEW">Make a New</option>
                                <option value="REUPLOAD">Document Reupload</option>
                            </select>
                        </div>
                        { panData.panStatus == 'REJECT' ?
                            <div className="col-md-4">
                                <label htmlFor="Reason">Reason</label>
                                <input type="text" className='form-control' name='reasonOfReject' value={ panData.reasonOfReject } onChange={ (e) => changeHandler(e) } />
                            </div> : null }
                    </div>
                </div>
                <center>
                    <button className="my-5 btn btn-primary" id='submit-btn'>Submit</button>
                </center>
            </form>
        </div>
    )
}
const mapPropsToState = (state) => ({

})

export default connect(mapPropsToState, { updatePanByAdmin })(PanUpdate)