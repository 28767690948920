import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getCodesUser } from '../../../action/Index';
import Loader from '../../loader/Loader';


const Codes = ({ update, getCodesUser, codes }) => {
    // const [codes, setCodes] = useState();
    // console.log('codes',codes)

    const [SearchByEmail, setSearchByEmail] = useState('');
    const [msg, setMsg] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getCodesUser(navigate);
    }, [])

    const a = () => {
        var x = document.getElementById('distA');
        if (x.style.display === "none") {
            x.style.display = "block";//width: '100%', height: '500px',  overflowY: 'scroll'
        } else {
            x.style.display = "none";
        }
    }
    const b = () => {
        var x = document.getElementById('distB');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const c = () => {
        var x = document.getElementById('distC');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }

    const d = () => {
        var x = document.getElementById('distD');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const e = () => {
        var x = document.getElementById('distE');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const f = () => {
        var x = document.getElementById('distF');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const g = () => {
        var x = document.getElementById('distG');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const h = () => {
        var x = document.getElementById('distH');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const i = () => {
        var x = document.getElementById('distI');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const j = () => {
        var x = document.getElementById('distJ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const k = () => {
        var x = document.getElementById('distK');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const l = () => {
        var x = document.getElementById('distL');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const m = () => {
        var x = document.getElementById('distM');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const n = () => {
        var x = document.getElementById('distN');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const o = () => {
        var x = document.getElementById('distO');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const p = () => {
        var x = document.getElementById('distP');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const q = () => {
        var x = document.getElementById('distQ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const r = () => {
        var x = document.getElementById('distR');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const s = () => {
        var x = document.getElementById('distS');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const t = () => {
        var x = document.getElementById('distT');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const u = () => {
        var x = document.getElementById('distU');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const v = () => {
        var x = document.getElementById('distV');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const w = () => {
        var x = document.getElementById('distW');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const x = () => {
        var x = document.getElementById('distX');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const y = () => {
        var x = document.getElementById('distY');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const z = () => {
        var x = document.getElementById('distZ');
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    }
    const setCity = (city) => {
        var table, tr, td, i, txtValue;
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName('tr');
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[1];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(city.toUpperCase()) > -1) {
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
        document.getElementById('theadtr').style.display = "";
        var y = document.getElementById(`dist${city.substring(0, 1)}`);
        if (table.style.display === "none") {
            table.style.display = "block";
            y.style.display = "none";
            table.style.width = '100%';
            table.style.height = '200px';
            table.style.overflowY = 'scroll';
        } else {
            table.style.display = "none";
        }
    }


    return codes == undefined || '' ? (<Loader />) : (
        <div className='mt-5'>
            <center>
                <div>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ a } to="#">A</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ b } to="#">B</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ c } to="#">C</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ d } to="#">D</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ e } to="#">E</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ f } to="#">F</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ g } to="#">G</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ h } to="#">H</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ i } to="#">I</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ j } to="#">J</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ k } to="#">K</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ l } to="#">L</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ m } to="#">M</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ n } to="#">N</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ o } to="#">O</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ p } to="#">P</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ r } to="#">R</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ s } to="#">S</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ t } to="#">T</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ u } to="#">U</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ v } to="#">V</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ w } to="#">W</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ y } to="#">Y</Link>
                    <Link style={ { padding: '10px', background: '#696969', color: '#fff' } } onClick={ z } to="#">Z</Link>
                </div>
            </center>
            <div id="distA" style={ { display: 'none' } } >
                <br />
                <p>Search has found 38 number of cities under <b>A</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('ABOHAR') }>ABOHAR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ADILABAD') }>ADILABAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ADONI') }>ADONI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('AGAR') }>AGAR</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('AGARTALA') }>AGARTALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AGRA') }>AGRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AHMEDABAD') }>AHMEDABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AHMEDNAGAR') }>AHMEDNAGAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('AJMER') }>AJMER</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AKOLA') }>AKOLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ALAPPUZHA') }>ALAPPUZHA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ALIGARH') }>ALIGARH</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ALIPURDUAR') }>ALIPURDUAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ALLAHABAD') }>ALLAHABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ALMORA') }>ALMORA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ALUVA') }>ALUVA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ALWAR') }>ALWAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMALAPURAM') }>AMALAPURAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMBALA') }>AMBALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMBEDKAR') }>AMBEDKAR NAGAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('AMBIKAPUR') }>AMBIKAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMETHI') }>AMETHI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMRAVATI') }>AMRAVATI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMRELI') }>AMRELI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('AMRITSAR') }>AMRITSAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AMROHA') }>AMROHA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ANAKAPALLI') }>ANAKAPALLI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ANAND') }>ANAND</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ANANTAPUR') }>ANANTAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ANANTNAG') }>ANANTNAG</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ANDAMAN') }>ANDAMAN &amp; NICOBAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ANGUL') }>ANGUL</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ARA') }>ARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ASANSOL') }>ASANSOL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ASHOK') }>ASHOK NAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AURAIYA') }>AURAIYA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('AURANGABAD') }>AURANGABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('AZAMGARH') }>AZAMGARH</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distB" style={ { display: 'none' } } >
                <br />
                <p>Search has found 71 number of cities under <b>B</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('BADAUN') }>BADAUN</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('BADDI') }>BADDI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('BAGALKOT') }>BAGALKOT</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('BAHRAICH') }>BAHRAICH</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BAJPUR') }>BAJPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BALAGHAT') }>BALAGHAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BALASORE') }>BALASORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BALLIA') }>BALLIA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BALOTRA') }>BALOTRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BALURGHAT') }>BALURGHAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BANDA') }>BANDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BANGALORE') }>BANGALORE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BANKURA') }>BANKURA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BANSWARA') }>BANSWARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BAPATLA') }>BAPATLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARABANKI') }>BARABANKI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BARAMULLA') }>BARAMULLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARAN') }>BARAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARAUT') }>BARAUT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARDOLI') }>BARDOLI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BAREILLY') }>BAREILLY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARGARH') }>BARGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARIPADA') }>BARIPADA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARMER') }>BARMER</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BARNALA') }>BARNALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARODA') }>BARODA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BARPETA') }>BARPETA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BASTI') }>BASTI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BATALA') }>BATALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BATHINDA') }>BATHINDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BEAWER') }>BEAWER</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BEED') }>BEED</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BEGUSARAI') }>BEGUSARAI</Link>
                            </td>
                            <td><Link to="#= onClick={this.beherampur}">BEHERAMPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BEHROR') }>BEHROR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BELGAUM') }>BELGAUM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BELLARY') }>BELLARY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BETTIAH') }>BETTIAH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BETUL') }>BETUL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHADOHI') }>BHADOHI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BHADRAK') }>BHADRAK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHAGALPUR') }>BHAGALPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHANDARA') }>BHANDARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHARATPUR') }>BHARATPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BHARUCH') }>BHARUCH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHATAPARA') }>BHATAPARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHAVNAGAR') }>BHAVNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHAWANIPATNA') }>BHAWANIPATNA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BHILAI') }>BHILAI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHILWARA') }>BHILWARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHIMAVARAM') }>BHIMAVARAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHIWADI') }>BHIWADI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BHIWANI') }>BHIWANI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHOPAL') }>BHOPAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BHUBANESWAR') }>BHUBANESWAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BIDAR') }>BIDAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BIHARSHARIF') }>BIHARSHARIF</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BIJAPUR') }>BIJAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BIJNORE') }>BIJNORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BIKANER') }>BIKANER</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BILASPUR') }>BILASPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BINA') }>BINA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BOKARO') }>BOKARO</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BOLANGIR') }>BOLANGIR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BONGAIGAON') }>BONGAIGAON</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BULANDSHAHAR') }>BULANDSHAHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BUNDI') }>BUNDI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BURDWAN') }>BURDWAN</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('BURHANPUR') }>BURHANPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BUXAR') }>BUXAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('BYRNIHAT') }>BYRNIHAT</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distC" style={ { display: 'none' } } >
                <br />
                <p>Search has found 19 number of cities under <b>C</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('CHAMARAJA') }>CHAMARAJA NAGAR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('CHANDAUSI') }>CHANDAUSI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('CHANDIGARH') }>CHANDIGARH</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('CHANDRAPUR') }>CHANDRAPUR</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('CHAPRA') }>CHAPRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHENNAI') }>CHENNAI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHHATTARPUR') }>CHHATTARPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHHINDWARA') }>CHHINDWARA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('CHIKABALLAPUR') }>CHIKABALLAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHIKMAGALUR') }>CHIKMAGALUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHIRALA') }>CHIRALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHITRADURGA') }>CHITRADURGA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('CHITTOOR') }>CHITTOOR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHITTORGARH') }>CHITTORGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CHURU') }>CHURU</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('COIMBATORE') }>COIMBATORE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('COOCH') }>COOCH BEHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CUDDALORE') }>CUDDALORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('CUTTACK') }>CUTTACK</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distD" style={ { display: 'none' } } >
                <br />
                <p>Search has found 35 number of cities under <b>D</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('DADRI') }>DADRI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('DAHOD') }>DAHOD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('DALHOUSIE') }>DALHOUSIE</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('DAMAN') }>DAMAN</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DAMOH') }>DAMOH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DARBHANGA') }>DARBHANGA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DARJEELING') }>DARJEELING</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DASUYA') }>DASUYA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DAUSA') }>DAUSA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DAVANAGERE') }>DAVANAGERE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DEHRADUN') }>DEHRADUN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DELHI') }>DELHI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DEOBAND') }>DEOBAND</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DEOGARH') }>DEOGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DEORIA') }>DEORIA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DEWAS') }>DEWAS</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DHAMPUR') }>DHAMPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHAMTARI') }>DHAMTARI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHANBAD') }>DHANBAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHAR') }>DHAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DHARAMSHALA') }>DHARAMSHALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHARMANAGAR') }>DHARMANAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHARMAPURI') }>DHARMAPURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHENKANAL') }>DHENKANAL</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DHUBRI') }>DHUBRI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DHULE') }>DHULE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DIBRUGARH') }>DIBRUGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DIGBOI') }>DIGBOI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DIMAPUR') }>DIMAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DINDIGUL') }>DINDIGUL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DULIAJAN') }>DULIAJAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DUMKA') }>DUMKA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('DUNGARPUR') }>DUNGARPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DURGAPUR') }>DURGAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('DWARKA') }>DWARKA</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distE" style={ { display: 'none' } } >
                <br />
                <p>Search has found 4 number of cities under <b>E</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('ELURU') }>ELURU</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ERODE') }>ERODE</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ETAH') }>ETAH</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ETAWAH') }>ETAWAH</Link>
                        </td>
                    </tr>
                    </tbody></table>
            </div>
            <div id="distF" style={ { display: 'none' } } >
                <br />
                <p>Search has found 8 number of cities under <b>F</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('FAIZABAD') }>FAIZABAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('FARIDABAD') }>FARIDABAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('FARIDKOT') }>FARIDKOT</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('FARRUKHABAD') }>FARRUKHABAD</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('FATEHABAD') }>FATEHABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('FATEHPUR') }>FATEHPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('FEROZEPUR') }>FEROZEPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('FIROZABAD') }>FIROZABAD</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distG" style={ { display: 'none' } } >
                <br />
                <p>Search has found 28 number of cities under <b>G</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('GADAG') }>GADAG</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('GANDHIDHAM') }>GANDHIDHAM</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('GANDHINAGAR') }>GANDHINAGAR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('GANGTOK') }>GANGTOK</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GAUTAM') }>GAUTAM BUDH NAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GAYA') }>GAYA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GHAZIABAD') }>GHAZIABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GHAZIPUR') }>GHAZIPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GIRIDIH') }>GIRIDIH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GOALPARA') }>GOALPARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GOBINDGARH') }>GOBINDGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GODHRA') }>GODHRA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GOKAK') }>GOKAK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GOLAGHAT') }>GOLAGHAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GONDA') }>GONDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GONDIA') }>GONDIA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GORAKHPUR') }>GORAKHPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GUDIWADA') }>GUDIWADA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GUDUR') }>GUDUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GULBARGA') }>GULBARGA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GUNA') }>GUNA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GUNTAKAL') }>GUNTAKAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GUNTUR') }>GUNTUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GURDASPUR') }>GURDASPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('GURGAON') }>GURGAON</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GURUVAYOOR') }>GURUVAYOOR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GUWAHATI') }>GUWAHATI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('GWALIOR') }>GWALIOR</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distH" style={ { display: 'none' } } >
                <br />
                <p>Search has found 23 number of cities under <b>H</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('HALDIA') }>HALDIA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('HALDWANI') }>HALDWANI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('HAMIRPUR') }>HAMIRPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('HANUMANGARH') }>HANUMANGARH</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('HAPUR') }>HAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HARDA') }>HARDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HARDOI') }>HARDOI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HARDWAR') }>HARDWAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('HASSAN') }>HASSAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HATHRAS') }>HATHRAS</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HAVERI') }>HAVERI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HAZARIBAGH') }>HAZARIBAGH</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('HIMMATNAGAR') }>HIMMATNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HINDUPUR') }>HINDUPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HINGOLI') }>HINGOLI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HISSAR') }>HISSAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('HOOGHLY') }>HOOGHLY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HOOGLY') }>HOOGLY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HOSHIARPUR') }>HOSHIARPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HOSPET') }>HOSPET</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('HOSUR') }>HOSUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HUBLI') }>HUBLI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('HYDERABAD') }>HYDERABAD</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distI" style={ { display: 'none' } } >
                <br />
                <p>Search has found 4 number of cities under <b>I</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('ICHALKARANJI') }>ICHALKARANJI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('IMPHAL') }>IMPHAL</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('INDORE') }>INDORE</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ITARSI') }>ITARSI</Link>
                        </td>
                    </tr>
                    </tbody></table>
            </div>
            <div id="distJ" style={ { display: 'none' } } >
                <br />
                <p>Search has found 26 number of cities under <b>J</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('JABALPUR') }>JABALPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('JAGDALPUR') }>JAGDALPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('JAGRAON') }>JAGRAON</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('JAIPUR') }>JAIPUR</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JAISALMER') }>JAISALMER</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JAJPUR') }>JAJPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JALANDHAR') }>JALANDHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JALGAON') }>JALGAON</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JALNA') }>JALNA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JALORE') }>JALORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JALPAIGURI') }>JALPAIGURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JAMMU') }>JAMMU</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JAMNAGAR') }>JAMNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JAMSHEDPUR') }>JAMSHEDPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JANJGIR') }>JANJGIR CHAMPA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JAUNPUR') }>JAUNPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JEYPORE') }>JEYPORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JHABUA') }>JHABUA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JHALAWAR') }>JHALAWAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JHANSI') }>JHANSI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JHARSUGUDA') }>JHARSUGUDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JHUNJHUNU') }>JHUNJHUNU</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JIND') }>JIND</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JODHPUR') }>JODHPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('JORHAT') }>JORHAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('JUNAGADH') }>JUNAGADH</Link>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distK" style={ { display: 'none' } } >
                <br />
                <p>Search has found 59 number of cities under <b>K</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('KADAPA') }>KADAPA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('KAITHAL') }>KAITHAL</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('KAKINADA') }>KAKINADA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('KALINGPONG') }>KALINGPONG</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KALPETTA') }>KALPETTA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KALYAN') }>KALYAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KANCHEEPURAM') }>KANCHEEPURAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KANKER') }>KANKER</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KANNAUJ') }>KANNAUJ</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KANNUR') }>KANNUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KANPUR') }>KANPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KAPURTHALA') }>KAPURTHALA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KARAIKUDI') }>KARAIKUDI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KARAULI') }>KARAULI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KARIMGANJ') }>KARIMGANJ</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KARIMNAGAR') }>KARIMNAGAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KARNAL') }>KARNAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KARUR') }>KARUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KARWAR') }>KARWAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KASARGOD') }>KASARGOD</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KASGANJ') }>KASGANJ</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KASHIPUR') }>KASHIPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KATHUA') }>KATHUA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KATIHAR') }>KATIHAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KATNI') }>KATNI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KATRA') }>KATRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KAUSHAMBI') }>KAUSHAMBI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KAWARDHA') }>KAWARDHA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KENDRAPADA') }>KENDRAPADA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KEONJHAR') }>KEONJHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHAMGAON') }>KHAMGAON</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHAMMAM') }>KHAMMAM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KHANDWA') }>KHANDWA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHANNA') }>KHANNA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHARGONE') }>KHARGONE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHATAULI') }>KHATAULI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KHATIMA') }>KHATIMA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KHURDA') }>KHURDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KISHENGARH') }>KISHENGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOCHI') }>KOCHI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KODERMA') }>KODERMA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOLAR') }>KOLAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOLHAPUR') }>KOLHAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOLKATA') }>KOLKATA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KOLLAM') }>KOLLAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOPPAL') }>KOPPAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KORBA') }>KORBA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOTA') }>KOTA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KOTDWAR') }>KOTDWAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOTHAGUDEM') }>KOTHAGUDEM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOTTAYAM') }>KOTTAYAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KOZHIKODE') }>KOZHIKODE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="# onClick={this.krishnagiri}">KRISHNAGIRI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KUDAL') }>KUDAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KULLU') }>KULLU</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KUMBAKONAM') }>KUMBAKONAM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('KURNOOL') }>KURNOOL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KURUKSHETRA') }>KURUKSHETRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('KUSHINAGAR') }>KUSHINAGAR</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distL" style={ { display: 'none' } } >
                <br />
                <p>Search has found 7 number of cities under <b>L</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('LAKHIMPUR') }>LAKHIMPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('LAKHISARAI') }>LAKHISARAI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('LALITPUR') }>LALITPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('LATUR') }>LATUR</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('LUCKNOW') }>LUCKNOW</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('LUDHIANA') }>LUDHIANA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('LUNAWADA') }>LUNAWADA</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distM" style={ { display: 'none' } } >
                <br />
                <p>Search has found 45 number of cities under <b>M</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('MACHILIPATNAM') }>MACHILIPATNAM</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('MADANAPALLE') }>MADANAPALLE</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('MADHUBANI') }>MADHUBANI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('MADIKERI') }>MADIKERI</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MADURAI') }>MADURAI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MAHABUBNAGAR') }>MAHABUBNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MAHARAJGANJ') }>MAHARAJGANJ</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MAHASAMUND') }>MAHASAMUND</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MAINPURI') }>MAINPURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MAKRANA') }>MAKRANA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MALDA') }>MALDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MALEGAON') }>MALEGAON</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MALERKOTLA') }>MALERKOTLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANCHIRIYAL') }>MANCHIRIYAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANDI') }>MANDI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANDLA') }>MANDLA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MANDSAUR') }>MANDSAUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANDYA') }>MANDYA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANENDRAGARH') }>MANENDRAGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANGALDOI') }>MANGALDOI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MANGALORE') }>MANGALORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MANSA') }>MANSA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MARGAO') }>MARGAO</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MARIGAON') }>MARIGAON</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MATHURA') }>MATHURA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MAU') }>MAU</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MEERUT') }>MEERUT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MEHSANA') }>MEHSANA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MIDNAPUR') }>MIDNAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MIRZAPUR') }>MIRZAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MODASA') }>MODASA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MOGA') }>MOGA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MORADABAD') }>MORADABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MORBI') }>MORBI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MORENA') }>MORENA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MOTIHARI') }>MOTIHARI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MOUNT') }>MOUNT ABU</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('Mumbai') }>Mumbai</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MUKTSAR') }>MUKTSAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MUMBAI') }>MUMBAI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MUNGER') }>MUNGER</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MURSHIDABAD') }>MURSHIDABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MUZAFFARNAGAR') }>MUZAFFARNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('MUZAFFARNAGAR') }>MUZAFFARPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('MYSORE') }>MYSORE</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distN" style={ { display: 'none' } } >
                <br />
                <p>Search has found 36 number of cities under <b>N</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('NABHA') }>NABHA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('NADIA') }>NADIA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('NADIAD') }>NADIAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('NAGAON') }>NAGAON</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NAGAPATTINAM') }>NAGAPATTINAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAGAUR') }>NAGAUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAGERCOIL') }>NAGERCOIL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAGPUR') }>NAGPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NAHAN') }>NAHAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAKODAR') }>NAKODAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NALBARI') }>NALBARI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NALGONDA') }>NALGONDA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NAMAKKAL') }>NAMAKKAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NANDED') }>NANDED</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NANDURBAR') }>NANDURBAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NANDYAL') }>NANDYAL</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NANITAL') }>NANITAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NARASARAOPET') }>NARASARAOPET</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NARNAUL') }>NARNAUL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NARSINGPUR') }>NARSINGPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NASHIK') }>NASHIK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NASIK') }>NASIK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAVSARI') }>NAVSARI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NAWANSHAHAR') }>NAWANSHAHAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NAZIBABAD') }>NAZIBABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NEEM') }>NEEM KA THANA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NEEMUCH') }>NEEMUCH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NELLORE') }>NELLORE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NIPPANI') }>NIPPANI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NIRMAL') }>NIRMAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NIZAMABAD') }>NIZAMABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NOHAR') }>NOHAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('NOIDA') }>NOIDA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NOKHA') }>NOKHA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NORTH') }>NORTH LAKHIMPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('NURPUR') }>NURPUR</Link>
                            </td>
                        </tr>


                    </tbody></table>
            </div>
            <div id="distO" style={ { display: 'none' } } >
                <br />
                <p>Search has found 4 number of cities under <b>O</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('ONGOLE') }>ONGOLE</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('OOTY') }>OOTY</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('ORAI') }>ORAI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('OSMANABAD') }>OSMANABAD</Link>
                        </td>
                    </tr>


                    </tbody></table>
            </div>
            <div id="distP" style={ { display: 'none' } } >
                <br />
                <p>Search has found 36 number of cities under <b>P</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('PALAKKAD') }>PALAKKAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('PALAKOL') }>PALAKOL</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('PALAMPUR') }>PALAMPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('PALANPUR') }>PALANPUR</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PALGHAR') }>PALGHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PALI') }>PALI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PANAJI') }>PANAJI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PANCHKULA') }>PANCHKULA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PANDHARPUR') }>PANDHARPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PANIPAT') }>PANIPAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PANVEL') }>PANVEL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PARADEEP') }>PARADEEP</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PARBHANI') }>PARBHANI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PARWANOO') }>PARWANOO</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PATAN') }>PATAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PATHANKOT') }>PATHANKOT</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PATIALA') }>PATIALA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PATNA') }>PATNA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PERAMBALUR') }>PERAMBALUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PETLAD') }>PETLAD</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PHAGWARA') }>PHAGWARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PHALODI') }>PHALODI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PHULBANI') }>PHULBANI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PILIBHIT') }>PILIBHIT</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PITHORAGARH') }>PITHORAGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('POLLACHI') }>POLLACHI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PORBANDAR') }>PORBANDAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PRATAPGARH') }>PRATAPGARH</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PRODDATUR') }>PRODDATUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PUDUCHERRY') }>PUDUCHERRY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PUDUKKOTTAI') }>PUDUKKOTTAI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PUNE') }>PUNE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('PURI') }>PURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PURNEA') }>PURNEA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PURULIA') }>PURULIA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('PUTTUR') }>PUTTUR</Link>
                            </td>
                        </tr>


                    </tbody></table>
            </div>
            <div id="distR" style={ { display: 'none' } } >
                <br />
                <p>Search has found 31 number of cities under <b>R</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('RAEBARELI') }>RAEBARELI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('RAICHUR') }>RAICHUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('RAIGANJ') }>RAIGANJ</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('RAIGARH') }>RAIGARH</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('RAIPUR') }>RAIPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAISEN') }>RAISEN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAJAHMUNDRY') }>RAJAHMUNDRY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAJAMAHENDRAVARAM') }>RAJAMAHENDRAVARAM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('RAJGARH') }>RAJGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAJKOT') }>RAJKOT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAJNANDGAON') }>RAJNANDGAON</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAJPURA') }>RAJPURA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('RAJSAMAND') }>RAJSAMAND</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAM') }>RAM NAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAMANATHAPURAM') }>RAMANATHAPURAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAMGARH') }>RAMGARH</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('RAMNAGAR') }>RAMNAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAMPUR') }>RAMPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAMPUR') }>RAMPUR BUSHAHR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RANCHI') }>RANCHI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('RATLAM') }>RATLAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RATNAGIRI') }>RATNAGIRI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RAYAGADA') }>RAYAGADA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('REWA') }>REWA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('REWARI') }>REWARI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RISHIKESH') }>RISHIKESH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ROHTAK') }>ROHTAK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ROORKEE') }>ROORKEE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ROPAR') }>ROPAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('ROURKELA') }>ROURKELA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('RUDRAPUR') }>RUDRAPUR</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>


                    </tbody></table>
            </div>
            <div id="distS" style={ { display: 'none' } } >
                <br />
                <p>Search has found 59 number of cities under <b>S</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('SAGAR') }>SAGAR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('SAHARANPUR') }>SAHARANPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('SAHARSA') }>SAHARSA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('SAHIBGANJ') }>SAHIBGANJ</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SALEM') }>SALEM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SAMANA') }>SAMANA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SAMASTIPUR') }>SAMASTIPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SAMBA') }>SAMBA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SAMBALPUR') }>SAMBALPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SAMBHAL') }>SAMBHAL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SANGAREDDY') }>SANGAREDDY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SANGLI') }>SANGLI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SANGRUR') }>SANGRUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SASARAM') }>SASARAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SATARA') }>SATARA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SATNA') }>SATNA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SAWAI') }>SAWAI MADHOPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SEHORE') }>SEHORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SENDHWA') }>SENDHWA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SEONI') }>SEONI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SHAHDOL') }>SHAHDOL</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHAHJAHANPUR') }>SHAHJAHANPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHAJAPUR') }>SHAJAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHAMLI') }>SHAMLI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SHILLONG') }>SHILLONG</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHIMLA') }>SHIMLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHIMOGA') }>SHIMOGA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SHIVPURI') }>SHIVPURI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SIBSAGAR') }>SIBSAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIDDHARTH') }>SIDDHARTH NAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIDDIPET') }>SIDDIPET</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIKAR') }>SIKAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SILCHAR') }>SILCHAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SILIGURI') }>SILIGURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SILVASSA') }>SILVASSA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SINGRAULI') }>SINGRAULI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SIRHIND') }>SIRHIND</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIROHI') }>SIROHI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIRSA') }>SIRSA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIRSI') }>SIRSI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SITAMARHI') }>SITAMARHI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SITAPUR') }>SITAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SIWAN') }>SIWAN</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SOLAN') }>SOLAN</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SOLAPUR') }>SOLAPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SONBHADRA') }>SONBHADRA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SONEPAT') }>SONEPAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SRIGANGANAGAR') }>SRIGANGANAGAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SRIKAKULAM') }>SRIKAKULAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SRINAGAR') }>SRINAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SULTANPUR') }>SULTANPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SUMERPUR') }>SUMERPUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SUNAM') }>SUNAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SUNDER') }>SUNDER NAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SURAT') }>SURAT</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SURATGARH') }>SURATGARH</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('SURENDRANAGAR') }>SURENDRANAGAR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SURI') }>SURI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('SURYAPET') }>SURYAPET</Link>
                            </td>
                            <td>&nbsp;</td>
                        </tr>


                    </tbody></table>
            </div>
            <div id="distT" style={ { display: 'none' } } >
                <br />
                <p>Search has found 31 number of cities under <b>T</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('TADEPALLIGUDEM') }>TADEPALLIGUDEM</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('TAMBARAM') }>TAMBARAM</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('TANUKU') }>TANUKU</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('TARN') }>TARN TARAN</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="A#" onClick={ () => setCity('TEHRI') }>TEHRI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TENALI') }>TENALI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TEZPUR') }>TEZPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('THANE') }>THANE</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('THANJAVUR') }>THANJAVUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('THENI') }>THENI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('THIRUVALLA') }>THIRUVALLA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('THIRUVANANTHAPURAM') }>THIRUVANANTHAPURAM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('THODUPUZHA') }>THODUPUZHA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('THRISSUR') }>THRISSUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIKAMGARH') }>TIKAMGARH</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TINSUKIA') }>TINSUKIA</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('TIPTUR') }>TIPTUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUCHENGODE') }>TIRUCHENGODE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUNELVELI') }>TIRUNELVELI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUPATHI') }>TIRUPATHI</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('TIRUPATI') }>TIRUPATI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUPPUR') }>TIRUPPUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUR') }>TIRUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TIRUVALLUR') }>TIRUVALLUR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('TIRUVANNAMALAI') }>TIRUVANNAMALAI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TONK') }>TONK</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TRICHY') }>TRICHY</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TRIVANDRUM') }>TRIVANDRUM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('TUMKUR') }>TUMKUR</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TUNI') }>TUNI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('TUTICORIN') }>TUTICORIN</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distU" style={ { display: 'none' } } >
                <br />
                <p>Search has found 7 number of cities under <b>U</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('UDAIPUR') }>UDAIPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('UDHAMPUR') }>UDHAMPUR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('UDUPI') }>UDUPI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('UJJAIN') }>UJJAIN</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('UMARIA') }>UMARIA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('UNA') }>UNA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('UNNAO') }>UNNAO</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distV" style={ { display: 'none' } } >
                <br />
                <p>Search has found 14 number of cities under <b>V</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('VADODARA') }>VADODARA</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('VAISHALI') }>VAISHALI</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('VALSAD') }>VALSAD</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('VAPI') }>VAPI</Link>
                        </td>
                    </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('VARANASI') }>VARANASI</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VELLORE') }>VELLORE</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VIDISHA') }>VIDISHA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VIJAYANAGARAM') }>VIJAYANAGARAM</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('VIJAYAWADA') }>VIJAYAWADA</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VIKARABAD') }>VIKARABAD</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VILLUPURAM') }>VILLUPURAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VIRUDHUNAGAR') }>VIRUDHUNAGAR</Link>
                            </td>
                        </tr><tr align="left">
                            <td><Link to="#" onClick={ () => setCity('VISAKHAPATNAM') }>VISAKHAPATNAM</Link>
                            </td>
                            <td><Link to="#" onClick={ () => setCity('VISHAKHAPATNAM') }>VISHAKHAPATNAM</Link>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div id="distW" style={ { display: 'none' } } >
                <br />
                <p>Search has found 2 number of cities under <b>W</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('WARANGAL') }>WARANGAL</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('WARDHA') }>WARDHA</Link>
                        </td>
                    </tr>
                    </tbody></table>
            </div>
            <div id="distY" style={ { display: 'none' } } >
                <br />
                <p>Search has found 3 number of cities under <b>Y</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody><tr align="left">
                        <td><Link to="#" onClick={ () => setCity('YADGIR') }>YADGIR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('YAMUNANAGAR') }>YAMUNANAGAR</Link>
                        </td>
                        <td><Link to="#" onClick={ () => setCity('YAVATMAL') }>YAVATMAL</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="distZ" style={ { display: 'none' } } >
                <br />
                <p>Search has found 1 number of cities under <b>Z</b></p>
                <br />
                <table border="0" width="100%">
                    <tbody>
                        <tr align="left">
                            <td><Link to="#" onClick={ () => setCity('ZIRA') }>ZIRA</Link></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="App mt-3" style={ { marginTop: '50px', marginBottom: '50px', textAlign: 'center' } } id="codes">
                <table border='2' className="table table-bordered text-center" width="100%" id="myTable" style={ { display: 'none' } } align="center">
                    <thead>
                        <tr id='theadtr'>
                            <td width="5%" valign="top"><b>Select</b></td>
                            <td valign="top" width="15%"><b>Ward/Circle/Range/ Commissioner</b></td>
                            <td valign="top" width="7%"><b>Discription</b></td>
                            <td valign="top" width="7%"><b>Area Code</b></td>
                            <td valign="top" width="7%"><b>AO Type</b></td>
                            <td valign="top" width="7%"><b>Range Code</b></td>
                            <td valign="top" width="7%"><b>AO Number</b></td>
                        </tr>
                    </thead>
                    { codes?.length ? codes.map((code, index) => {
                        return (
                            <tbody key={ index }>
                                <tr>
                                    <td><input className="form-check-input" type="radio" name="aoDetail" onClick={ () => {
                                        update({ areacode: code.areacode, aotype: code.aotype, rangecode: code.rangecode, aonumber: code.aonumber })
                                        const a = document.getElementById('myTable');
                                        a.style.display = 'none'
                                    } } value={ `${code.areacode}~${code.aotype}~${code.rangecode}~${code.aonumber}` } /></td>
                                    <td>{ code.discription }</td>
                                    <td>{ code.discription1 }</td>
                                    <td>{ code.areacode }</td>
                                    <td>{ code.aotype }</td>
                                    <td>{ code.rangecode }</td>
                                    <td>{ code.aonumber }</td>
                                </tr>
                            </tbody>
                        )
                    }) : null }
                </table>
            </div>
        </div>
    );
}

const mapPropsToState = (state) => ({
    codes: state?.getCodesForUser?.data
})

export default connect(mapPropsToState, { getCodesUser })(Codes)