import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { setAuthToken, setAuthTokenAdmin } from './utils/setAuthToken';

const initialState = {};

const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

let currentState = store.getState();

store.subscribe(() => {
    let previousState = currentState;
    currentState = store.getState();
    // console.log(currentState.adminAuth.token);
    // if the token changes set the value in localStorage and axios headers
    if (previousState?.loginReducer?.token !== currentState?.loginReducer?.token) {
        const token = currentState?.loginReducer?.token;
        setAuthToken(token);
    }
    if (previousState?.adminAuth?.token !== currentState?.adminAuth?.token) {
        const token = currentState?.adminAuth?.token;
        setAuthTokenAdmin(token);
    }

});

export default store;

