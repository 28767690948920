import React from 'react';

const About = () => {
    return (
        <div>
            <div className='container'>
                <div className='card' >
                    <div className='row'>
                        <div>
                            <h4 className="card-header">About us</h4>
                        </div>
                        <div className=' col-xs-12 col-sm-6 col-md-8 col-lg-8'>
                            <div className=''>
                                <div className='card-body'>
                                    <b>W</b>elcome to <b>CSC PAN CARD</b> Corporation, your number one source for all digital things. We're dedicated to giving you the very best of online services, with a focus on hassle-free, earning and satisfaction.
                                    <br />
                                    <br />
                                    Our company has come a long way from its beginnings in India. When we first started out, our passion for technology drove them to do tons of research so that <b>CSC PAN CARD</b> Corporation can offer you India's most advanced toothbrush. We now serve customers all over the country and are thrilled that we're able to turn our passion into your own website.
                                    <br />
                                    We hope you enjoy our products as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <img className='card-img-top' src="images/hero_1.jpg" alt="green iguana" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
