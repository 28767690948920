import React from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createNewPassword } from '../../../action/Index';

const CreatePassword = ({ createNewPassword }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const formik = useFormik({
        initialValues: {
            password: '',
            token: params.token
        },
        onSubmit: values => {
            dispatch(createNewPassword(values, navigate))
        },
    });
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="d-flex col-md-10 justify-content-center align-items-center vh-100">
                        <form onSubmit={ formik.handleSubmit } autoComplete='off' className='p-5 border rounded-3 bg-light'>
                            <h5 className="text-center">Forgot Password</h5>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    className="form-control"
                                    autoComplete='off'
                                    name='password'
                                    onChange={ formik.handleChange }
                                    value={ formik.values.password }
                                    id="exampleInputPassword1" />
                                { formik?.errors?.password ? <small className='form-text text-danger'>{ formik?.errors?.password }</small> : null }
                            </div>
                            <button type="submit" className="btn btn-primary rounded-pill coll-8">Send</button>
                            <Link className='m-3' to={ '/' }>Login</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { createNewPassword })(CreatePassword)