import axios from 'axios';
import { LOG_OUT } from '../action/Types';
import store from '../Store';
const token = localStorage.getItem('token');
// Create an instance of axios and using api for calling API
export const api = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});
export const apiAdmin = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});
/*
  NOTE: intercept any error responses from the api
 and check if the token is no longer valid.
 ie. Token has expired or user is no longer
 authenticated.
 logout the user if the token has expired
*/

api.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response.status === 401) {
            store.dispatch({ type: LOG_OUT });
        }
        return Promise.reject(err);
    }
);