import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateUserProfile } from '../../../action/admin/Index';

const UsersProfile = ({ updateUserProfile }) => {
    const { state } = useLocation();
    // console.log('state', state);
    const navigate = useNavigate();

    const [userdata, setUserdata] = useState({
        id: state?._id,
        fullname: state?.fullname || '',
        username: state?.username || '',
        email: state?.email || '',
        mobile: state?.mobile || '',
        address: state?.address || '',
        aadhar: state?.aadhar || '',
        pannumber: state?.pannumber || '',
        address: state?.address || '',
        panrate: state?.panrate || '',
        status: state?.status || '',
        wallet: state?.wallet || '',
    })

    const changeHandler = (e) => {
        setUserdata({ ...userdata, [e.target.name]: e.target.value })
    }
    const submit = (e) => {
        e.preventDefault();
        updateUserProfile(userdata, navigate)
    }
    return (
        <div>
            <div className="container-fluid mt-5">
                <div className="row mx-5">
                    <div className="card my-5">
                        <div className="card-header">
                            <h4 className="text-center">Retailer Profile</h4>
                        </div>
                        <form onSubmit={ submit }>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Id :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ state?.forgianId } readOnly className={ 'form-control' } placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>User Name :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ userdata?.username } readOnly className='form-control' placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Wallet :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='wallet' onChange={ (e) => changeHandler(e) } value={ userdata?.wallet } className='form-control' placeholder='wallet' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Pan Rate :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='panrate' value={ userdata?.panrate } className='form-control' placeholder='panrate' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Full Name :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='fullname' value={ userdata.fullname } className='form-control' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>E-mail :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='email' value={ userdata.email } className='form-control' placeholder='E-mail' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Mobile :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='mobile' value={ userdata.mobile } className='form-control' placeholder='Mobile' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Aadhar N. :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='aadhar' value={ userdata.aadhar } className='form-control' placeholder='Aadhar Number' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Pan N. :</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='pannumber' value={ userdata.pannumber } className='form-control' placeholder='Pan Number' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Status :</p>
                                </div>
                                <div className="col-md-6">
                                    <select className="form-select" onChange={ (e) => changeHandler(e) } name='status' value={ userdata.status }>
                                        <option selected>-- Select --</option>
                                        <option value="true">Active</option>
                                        <option value="false">Deactive</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-3">
                                    <p className='text-start'>Address :</p>
                                </div>
                                <div className="col-md-6">
                                    <textarea type="text" onChange={ (e) => changeHandler(e) } name='address' value={ userdata.address } className='form-control' placeholder='Address' rows={ '5' } />
                                </div>
                            </div>
                            <center className='my-2'>
                                <button className="btn btn-primary">Update</button>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { updateUserProfile })(UsersProfile)