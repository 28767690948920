import React from 'react'
import Home from './Home'

const Dashboard = () => {
    return (
        <div className='my-5'>
            <Home />
        </div>
    )
}

export default Dashboard