import { toast } from 'react-toastify';
import { ADMIN_LOGIN, ADMIN_LOGOUT, GET_ADMIN_PAYMENTS, GET_BACKOFFICE_PASS_USER, GET_BANK_ADMIN, GET_CODES_ADMIN, GET_NEW_FOR_USER, GET_PAN_ADMIN, GET_PAN_BY_USERID_ADMIN, GET_USERS, GET_USER_PAYMENTS_ADMIN, GET_USER_PAYMENT_HISTORY_ADMIN, INVELID_AUTH } from '../Types';
import { apiAdmin } from '../../utils/api';
import { loader } from '../Index';


/**
 * Login 
 */
export const adminLogin = (e, navigate) => async (dispatch) => {
    // console.log(e);
    try {
        dispatch(loader(true));
        const logindata = {
            username: e.username,
            password: e.password
        }
        const res = await apiAdmin.post(`/admin/auth/admin/login`, logindata);
        // console.log(res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            navigate('/admin/dashboard')
            toast.success(res.data.msg[0].msg)
            dispatch({
                type: ADMIN_LOGIN,
                payload: res.data.token
            })
        }
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error)
        toast.error(error?.response?.data?.msg[0]?.msg)
    }
}


/**
 * 
 * @param {*} navigate Function
 * @returns logout
 */
export const Adminlogout = (navigate) => (dispatch) => {
    setTimeout(() => {
        navigate("/admin", { replace: true });
    }, 0);
    dispatch({
        type: ADMIN_LOGOUT
    })
}

/**
 * 
 * @param {*} e Data for send
 * @param {*} navigate Function
 * @returns 
 */
export const getUsers = (navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.get('/admin/users/allusers');
        // console.log(res.data.data);
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_USERS,
                payload: res.data.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error);
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            dispatch({
                type: INVELID_AUTH
            })
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} data in json
 * @param {*} navigate fuction
 * @returns res json
 */
export const updateUserProfile = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        // console.log('data', data)
        const res = await apiAdmin.post(`/admin/users/profile/update`, data);
        // console.log(res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        console.log('error', error)
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            dispatch({
                type: INVELID_AUTH
            })
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} navigate function for navigate
 * @returns res as json
 */
export const getAdminPans = (limit, skip, filter, panId, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            limit: limit,
            skip: skip,
            filter: filter,
            panId: panId,
            navigate: navigate
        }
        const res = await apiAdmin.post('/admin/pans/pans', data);
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_PAN_ADMIN,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error);
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            dispatch({
                type: INVELID_AUTH
            })
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}


/**
 * @param {*} data payment data as json
 * @param {*} navigate function
 * @returns 
 */
export const getPanByUserIdAdmin = (userId, limit, skip, filter, panId, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            id: userId,
            limit: limit,
            skip: skip,
            filter: filter,
            panId: panId,
        }
        // console.log(data)
        const res = await apiAdmin.post(`/admin/pans/user/all/pans`, data);
        // console.log(res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_PAN_BY_USERID_ADMIN,
                payload: res.data,
            });
        }
    } catch (error) {
        console.log('error', error.response.data);
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} data as json
 * @param {*} navigate function for navigate
 * @returns json
 */
export const slipUPloadByAdmin = (data, navigate, e) => async (dispatch) => {
    try {
        e.preventDefault();
        dispatch(loader(true));
        // console.log(data)
        const formData = new FormData();
        formData.append('slip', data.slip);

        const res = await apiAdmin.put(`/admin/pans/updateforslip/${data.id}`, formData, { headers: { "Content-Type": 'multipart/form-data' } });
        if (res.status === 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
        }
        window.stop();
    } catch (error) {
        console.log('error', error);
        dispatch(loader(false));
        if (error.response) {
            toast.error(error.response.data.msg[0].msg)
        }
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}

/**
 * 
 * @param {*} data pan data for update as json
 * @param {*} navigate Function for navigate
 * @returns 
 */
export const updatePanByAdmin = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        // console.log(data)
        const res = await apiAdmin.post('/admin/pans/update', data)
        // console.log(res.data)
        if (res.status === 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
            navigate('/admin/pans')
        }
    } catch (error) {
        console.log('error', error)
        dispatch(loader(false));
        if (error.response.status == 401) {
            toast.error(error?.response?.data?.msg[0]?.msg)
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}


/**
 * 
 * @param {*} limit Number
 * @param {*} skip Number
 * @param {*} filter String
 * @param {*} navigate function
 * @returns 
 */
export const getAdminPayments = (limit, skip, filter, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            limit: limit,
            skip: skip,
            filter: filter,
        }
        const res = await apiAdmin.post(`/admin/payments/allPayments`, data);
        if (res.status == 200) {
            dispatch(loader(false));
            // console.log(res.data);
            dispatch({
                type: GET_ADMIN_PAYMENTS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} limit Number
 * @param {*} skip Number
 * @param {*} filter String
 * @param {*} navigate function
 * @returns 
 */
export const getUserPaymentAdmin = (userId, limit, skip, filter, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            id: userId,
            limit: limit,
            skip: skip,
            filter: filter,
        }
        const res = await apiAdmin.post(`/admin/payments/user/all/payment`, data);
        if (res.status == 200) {
            dispatch(loader(false));
            // console.log(res.data);
            dispatch({
                type: GET_USER_PAYMENTS_ADMIN,
                payload: res.data
            })
        }
    } catch (error) {
        toast.error(error?.response?.data?.msg[0]?.msg)
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}


/**
 * @param {*} data payment data as json
 * @param {*} navigate function
 * @returns 
 */
export const updatePayment = (data, navigate) => async (dispatch) => {
    try {
        // console.log(data)
        dispatch(loader(true));
        const res = await apiAdmin.post(`/admin/payments/update`, data);
        // console.log(res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
            navigate('/admin/payments')
        }
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error);
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * @param {*} data payment data as json
 * @param {*} navigate function
 * @returns 
 */
export const getUserPaymentHistoryAdmin = (userId, limit, skip, filter, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            id: userId,
            limit: limit,
            skip: skip,
            filter: filter,
        }
        // console.log(data)
        const res = await apiAdmin.post(`/admin/payments/user/paymenthistory`, data);
        // console.log(res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            // console.log(res.data);
            dispatch({
                type: GET_USER_PAYMENT_HISTORY_ADMIN,
                payload: res.data
            })
        }
    } catch (error) {
        console.log('error', error);
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} limit number
 * @param {*} skip number
 * @param {*} navigate function
 * @returns 
 */
export const getCodes = (limit, skip, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.get(`/admin/codes/get/codes?limit=${limit}&skip=${skip}`)
        // console.log(res.data)
        if (res.data.length > 0) {
            dispatch(loader(false));
            dispatch({
                type: GET_CODES_ADMIN,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error);
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('tokens');
            navigate('/admin');
        }
    }
}

/**
 * 
 * @param {*} data json codes
 * @param {*} navigate function
 * @returns 
 */
export const addCodes = (data, navigate) => async (dispatch) => {
    try {
        // console.log(data)
        dispatch(loader(true));
        if (data._id) {
            const res = await apiAdmin.put(`/admin/codes/update/${data._id}`, data);
            if (res.status === 200) {
                dispatch(loader(false));
                toast.success(res.data.msg[0].msg)
            }
        } else {
            const res = await apiAdmin.post(`/admin/codes/add`, data);
            if (res.status === 200) {
                dispatch(loader(false));
                toast.success(res.data.msg[0].msg)
            }
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.data.msg) {
            toast.error(error.response.data.msg[0].msg)
        }
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}

/**
 * 
 * @param {*} data json codes
 * @param {*} navigate function
 * @returns 
 */
export const deleteCode = (codeid, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.delete(`/admin/codes/delete/${codeid}`);
        if (res.status === 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        dispatch(loader(false));
        console.log(error.response);
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}


/**
 * 
 * @param {*} data json backoffice user pass
 * @param {*} navigate function
 * @returns 
 */
export const getBackOfficeUserPass = (codeid, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.get('/admin/bank/get/username/password');
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_BACKOFFICE_PASS_USER,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}

/**
 * 
 * @param {*} data json backoffice user pass
 * @param {*} navigate function
 * @returns 
 */
export const updateBackOfficeUserPass = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        // console.log(data)
        const res = await apiAdmin.put(`/admin/bank/add/username/password/backoffice/${data.id}`, data);
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_BACKOFFICE_PASS_USER,
                payload: res.data
            })
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}


/**
 * 
 * @param {*} data 
 * @param {*} navigate function
 * @returns 
 */
export const getBank = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.get('/admin/bank/admin/new/bank');
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_BANK_ADMIN,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}

/**
 * 
 * @param {*} data json Bank data
 * @param {*} navigate function
 * @returns 
 */
export const updateBank = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.put(`/admin/bank/admin/newbank/update/${data.id}`, data);
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}

/**
 * 
 * @param {*} navigate function for navigate
 * @returns 
 */
export const getNew = (navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await apiAdmin.get('admin/bank/get/news');
        // console.log(res.data)
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_NEW_FOR_USER,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('tokens')
            navigate('/admin')
        }
    }
}