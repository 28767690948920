import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { updatePayment } from '../../../action/admin/Index';

const Payment = ({ updatePayment }) => {
    const { state } = useLocation();
    const [acceptStatus, setAcceptStatus] = useState();
    const [reasonOfReject, setReasonOfReject] = useState();
    const navigate = useNavigate();
    // console.log(state);
    const sendData = async (e) => {
        e.preventDefault();
        if (acceptStatus == 'Rejected' && (reasonOfReject == '' || reasonOfReject == undefined) || acceptStatus == undefined) {
            alert('Please ad reason of reject');
            return false;
        }
        const data = {
            id: state?._id,
            acceptStatus: acceptStatus,
            reasonOfReject: reasonOfReject
        }
        updatePayment(data, navigate)
    }

    return (
        <div className='container-fluid mt-5'>
            <div className="row">
                <div className='d-flex justify-content-center align-items-center'>
                    <div className="card mt-5 ">
                        <div className="card-header"><h4 className="text-center">Payment Accept/Reject</h4></div>
                        <div className="card-body">
                            <center>
                                <form onSubmit={ sendData }>
                                    <div>
                                        <label htmlFor="accept">Accept</label>&nbsp;&nbsp;
                                        <input type="radio" value="Accepted" onChange={ (e) => setAcceptStatus(e.target.value) } name="acceptStatus" id="acceptStatus" />
                                    </div>
                                    <div>
                                        <label htmlFor="accept">Reject</label>&nbsp;&nbsp;
                                        <input type="radio" onChange={ (e) => setAcceptStatus(e.target.value) } value="Rejected" name="acceptStatus" id="reject" />
                                    </div>
                                    { acceptStatus == 'Rejected' ? <span>
                                        <input type="text" className='form-control form-control-sm mt-2' placeholder='Please add reasonn of reject' value={ reasonOfReject } name="reasonOfReject" onChange={ (e) => setReasonOfReject(e.target.value) } />&nbsp;
                                    </span> : null }

                                    <input type="submit" className='btn  btn-primary mt-2' value="Submit" />
                                </form>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({});
export default connect(mapStateToProps, { updatePayment })(Payment)