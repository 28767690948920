import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getUserPaymentAdmin } from '../../../action/admin/Index';
import moment from 'moment';

const UserPayments = ({ getUserPaymentAdmin, payments }) => {
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [filter, setFilter] = useState('');
    const [paymentid, setpaymentid] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log('payments', state)

    useEffect(() => {
        getUserPaymentAdmin(state?.paymentBy, limit, skip, filter, navigate)
    }, [limit, filter])

    const scrollToEnd = () => {
        setLimit(limit + skip)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }

    return (
        <div className="container-fluid my-5">
            <div className="row  mx-5">
                <div className="card mt-5">
                    <div className="card-header">
                        <h4><span className='badge bg-primary'>{ state?.username?.replace(/_/g, ' ') }</span> Payments</h4>
                    </div>
                    <div className="card-body">
                        <div className='mb-3'>
                            <div className="row">
                                <div className="col-md-3">
                                    <select name="filter" className='form-select' id="filter" onChange={ (e) => setFilter(e.target.value) } value={ filter }>
                                        <option>-Select-</option>
                                        <option value="VERIFY">VERIFY</option>
                                        <option value="REJECTED">REJECTED</option>
                                        <option value="PANDING">PENDING</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <input type="number" value={ limit } className='form-control' onChange={ (e) => setLimit(e.target.value) } name="limit" id="limit" placeholder='limit' />
                                </div>
                                {/* <div className="col-md-3">
                                    <input type="submit" value="Click" className='btn bg-dark text-light' />
                                </div> */}
                            </div>
                        </div>
                        <table className="table table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">SR.</th>
                                    <th scope="col">Payment Id</th>
                                    <th scope="col">Transaction Date/Time</th>
                                    <th scope="col">TransFer Code</th>
                                    <th scope="col">Bank Name</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    payments?.data?.map((data, i) => (
                                        <tr key={ i }>
                                            <th scope="row">{ i + 1 }</th>
                                            <td>{ data.forgianId }</td>
                                            <td>{ moment(data.date).format('LLL') }</td>
                                            <td>{ data.tranferRefCode }</td>
                                            <td>{ data.bankName }</td>
                                            <td>{ data.acceptStatus == false && !data.reasonOfReject ?
                                                <Link to='/admin/payment' state={ data } className='text-decoration-none'>
                                                    Pending
                                                </Link> :
                                                data.acceptStatus == true && !data.reasonOfReject ?
                                                    <span className='text-success'>Accepted</span>
                                                    : data.acceptStatus == false && data.reasonOfReject ?
                                                        <span className='text-danger'>Rejected,{ data.reasonOfReject }</span>
                                                        : null
                                            }</td>
                                            <td>{ data.amount }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => ({
    payments: state?.getPaymentAdmin?.paymentByUserId
})
export default connect(mapPropsToState, { getUserPaymentAdmin })(UserPayments)