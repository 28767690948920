import React from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { forgotPassword } from '../../../action/Index';

const ForgotPassword = ({ forgotPassword }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: values => {
            dispatch(forgotPassword(values, navigate))
        },
    });
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="d-flex col-md-10 justify-content-center align-items-center vh-100">
                        <form onSubmit={ formik.handleSubmit } autoComplete='off' className='p-5 border rounded-3 bg-light'>
                            <h5 className="text-center">Forgot Password</h5>
                            <div className="mb-3">
                                <label
                                    htmlFor="email"
                                    className="form-label">
                                    Email
                                </label>
                                <input type="email"
                                    className="form-control"
                                    autoComplete='off'
                                    id="email"
                                    name='email'
                                    placeholder='Enter your registered email'
                                    onChange={ formik.handleChange }
                                    value={ formik.values.email }
                                    aria-describedby="email" />
                                { formik?.errors?.email ? <small className='form-text text-danger'>{ formik?.errors?.email }</small> : null }
                            </div>
                            <button type="submit" className="btn btn-primary rounded-pill coll-8">Send</button>
                            <Link className='m-3' to={ '/' }>Login</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword)