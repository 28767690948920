import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment';
import { getCodes, addCodes, deleteCode } from '../../../action/admin/Index';
import { connect } from 'react-redux';


const Codes = ({ getCodes, codesdata, addCodes, deleteCode }) => {
    const [limit, setLimit] = useState(20);
    const [skip, setSkip] = useState(0);
    const navigate = useNavigate();
    const [aodata, setAodata] = useState({
        areacode: '',
        aotype: '',
        aonumber: '',
        discription: '',
        district: '',
        rangecode: '',
    })
    // console.log('codesdata', codesdata);

    const changeHandle = (e) => {
        setAodata({ ...aodata, [e.target.name]: e.target.value })
    }
    const scrollToEnd = () => {
        setLimit(limit + 20)
    }

    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }

    const codeSubmit = async (e) => {
        e.preventDefault();
        if (aodata.areacode == '' || aodata.aotype == '' || aodata.aonumber == '' || aodata.rangecode == '' || aodata.discription == '' || aodata.district == '') {
            alert('Please fill the inputs');
            return false;
        }
        addCodes(aodata, navigate);
        setTimeout(() => {
            setAodata({ areacode: '', aotype: '', aonumber: '', rangecode: '', discription: '', district: '' });
            getCodes(limit, skip, navigate);
        }, 2000);
    }

    useEffect(() => {
        getCodes(limit, skip, navigate);
    }, [limit, skip])


    /**@thisIsForEDitPanCodes */
    const ForEdit = async (code) => {
        setAodata(code);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    /**@thisIsForDeleteCode */
    const ForDelete = async (codeid) => {
        const res = window.confirm('Are you sure Delete this Code')
        if (res == true) {
            deleteCode(codeid, navigate)
            getCodes(limit, skip, navigate);
        }
    }

    return (
        <div className="container-fluid" >
            <div className='row mx-3'>
                <div className="card my-5">
                    <div className="card-header bg-dark">
                        <h4 className="text-center text-light">Area codes Add</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ e => codeSubmit(e) }>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="areacode">Area code</label>
                                        <input type="text" onChange={ e => changeHandle(e) } value={ aodata.areacode } name="areacode" id="areacode" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="aotype">Ao type</label>
                                        <input type="text" onChange={ e => changeHandle(e) } name="aotype" value={ aodata.aotype } id="aotype" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="rangecode">Range code</label>
                                        <input type="text" onChange={ e => changeHandle(e) } value={ aodata.rangecode } name="rangecode" id="rangecode" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="aonumber">Ao Number</label>
                                        <input type="text" onChange={ e => changeHandle(e) } name="aonumber" value={ aodata.aonumber } id="aonumber" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="discription1">Ward/Circle/Range/ Commissioner</label>
                                        <input type="text" onChange={ e => changeHandle(e) } value={ aodata.discription } name="discription" id="discription" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-field">
                                        <label className='form-label' htmlFor="district">District</label>
                                        <input type="text" onChange={ e => changeHandle(e) } value={ aodata.district } name="district" id="district" className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-dark mt-3">Code Add</button>
                        </form>
                    </div>
                </div>
                <div className="mt-3 ">
                    <div className="card-header bg-dark">
                        <h4 className="text text-center text-light">Codes</h4>
                    </div>
                    <div className="card-body shadow">
                        <table id="myTable" className='table table-hover text-center table-responsive table-bordered'>
                            <thead>
                                <tr>
                                    <th scope="col">Sr. N.</th>
                                    <th scope="col">Area Code</th>
                                    <th scope="col">Ao Type</th>
                                    <th scope="col">Range code</th>
                                    <th scope="col">Ao number</th>
                                    <th scope="col">Ward/Circle/Range/ Commissioner</th>
                                    {/* <th scope="col">Discription</th> */ }
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    codesdata?.map((code, i) => (
                                        <tr key={ i }>
                                            <td>{ i + 1 }</td>
                                            <td>{ code.areacode }</td>
                                            <td>{ code.aotype }</td>
                                            <td>{ code.rangecode }</td>
                                            <td>{ code.aonumber }</td>
                                            <td>{ code.discription }</td>
                                            {/* <td style={{ wordWrap: 'breakWord' , width: '100px'}} >{code.discription1}</td> */ }
                                            <td>
                                                { moment(code.date).format('DD-MM-YYY') }
                                            </td>
                                            <td>
                                                <Link className='text-decoration-none' to={ '#' } onClick={ () => ForDelete(code._id) }>
                                                    <b>Delete</b>
                                                </Link>
                                                &nbsp;
                                                <Link className='text-decoration-none' to={ '#' } onClick={ () => ForEdit(code) }>
                                                    <b>Edit</b>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = state => ({
    codesdata: state?.getCodesAdmin?.data
});
export default connect(mapStateToProps, { getCodes, addCodes, deleteCode })(Codes)