import React from 'react';


const Services = () => {
    return (
        <div style={ { marginTop: '105px' } }>
            <div className='container'>
                <div className='card'>
                    <div className="card-header">
                        <h4 className="text-center">Our Services</h4>
                    </div>
                    <div className='row  m-2'>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img src="images/aeps.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>AEPS</h4>
                                    </div>
                                    <div>
                                        Aadhaar enabled Payment System (AEPS) is a secured payment method provided by the National Payments Corporation of India (NPCI) that allows Aadhaar linked bank account holders to avail basic banking services such as balance inquiry & cash withdrawal.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img src="images/moneyTransfer.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>MONEY TRANSFER</h4>
                                    </div>
                                    <div>
                                        Fund transfers will now be made easy with Cash to Account. Carry in cash and walk-in to any Fino Payments Bank branch or select merchant establishments, fill in few details and have the money transferred to your loved ones, anywhere in the country.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img variant="top" src="images/mobileRecharg.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>MOBILE RECHARGE</h4>
                                    </div>
                                    <div>
                                        parasnet.co.in covers major network providers in India, that include Airtel, Aircel, Vodafone, BSNL, Idea, Tata Docomo (GSM), Uninor, etc. Not only that, to facilitate the online recharges, the latest talk time plans and data packs are updated on the our website accordingly.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img variant="top" src="images/pancard.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>PAN CARD</h4>
                                    </div>
                                    <div>
                                        A permanent account number is a ten-character alphanumeric identifier, issued in the form of a laminated "PAN card", by the Indian Income Tax Department, to any "person" who applies for it or to whom the department allots the number without an application.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img variant="top" src="images/bbps.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>BBPS</h4>
                                    </div>
                                    <div>
                                        Bharat Bill Payment System is an integrated bill payment system in India offering interoperable and accessible bill payment service to customers through a network of agents of registered member as Agent Institutions, enabling multiple payment modes, and providing instant confirmation of payment.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='my-1 col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div className='card'>
                                <img variant="top" src="images/fastage.jpg" height="140" alt="green iguana" />
                                <div className='card-body'>
                                    <div className='card-title'>
                                        <h4>FASTAG</h4>
                                    </div>
                                    <div>
                                        FASTag is an electronic toll collection system in India, operated by the National Highway Authority of India. It employs Radio Frequency Identification technology for making toll payments directly from the prepaid or savings account linked to it or directly toll owner.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Services;
