import React from 'react';

const Contact = () => {
    return (
        <div className='container' style={ { marginTop: '105px' } }>
            <div className='card'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <div>CSC PAN CARD CORPORATION</div>
                            <p>Uttar Pradesh</p>
                            <div>Call Us</div>
                            <p>+91 94125 81220</p>
                            <div>Email-ID</div>
                            <a href='mailto:digitalindiafast1@gmail.com'>digitalindiafast1@gmail.com</a>
                        </div>
                        <div className='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                            <form>
                                <div style={ { paddingTop: '10px' } }>
                                    <input className='form-control' type="text" placeholder="Name" />
                                </div>
                                <div style={ { paddingTop: '10px' } }>
                                    <input className='form-control' type="email" placeholder="Email" />
                                </div>
                                <div style={ { paddingTop: '10px' } }>
                                    <input className='form-control' rows={ 3 } placeholder="Message" />
                                </div>
                                <div style={ { paddingTop: '10px' } }>
                                    <button className='btn btn-primary' type="submit">
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
