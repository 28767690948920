import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserProfile, updateProfile } from '../../../action/Index';

const Profile = ({ getUserProfile, profile, updateProfile }) => {
    const navigate = useNavigate();
    const [userdata, setUserdata] = useState({
        id: profile?._id,
        fullname: profile?.fullname || '',
        email: profile?.email || '',
        mobile: profile?.mobile || '',
        address: profile?.address || '',
        aadhar: profile?.aadhar || '',
        pannumber: profile?.pannumber || ''
    })

    useEffect(() => {
        getUserProfile(navigate);
    }, [])


    const changeHandler = (e) => {
        setUserdata({ ...userdata, [e.target.name]: e.target.value })
    }
    const submit = (e) => {
        e.preventDefault();
        updateProfile(userdata, navigate)
    }
    return (
        <div className="container my-5">
            <div className="row">
                <div className="card my-5">
                    <div className="card-header">
                        <h4 className="text-center">My Profile</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ submit }>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Branch Code:</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ profile?.forgianId } readOnly className={ 'form-control' } placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Id Status</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ profile?.status == false ? 'Deactivate' : "Active" } readOnly className={ profile?.status == false ? 'bg-danger text-light form-control' : 'bg-success text-light form-control' } placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>User Name</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ profile?.username } readOnly className='form-control' placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Wallet</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ profile?.wallet } readOnly className='form-control' placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Pan Rate</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" name='username' value={ profile?.panrate } readOnly className='form-control' placeholder='User name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Full Name</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='fullname' value={ userdata.fullname } className='form-control' placeholder='Full Name' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>E-mail</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='email' value={ userdata.email } className='form-control' placeholder='E-mail' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Mobile</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='mobile' value={ userdata.mobile } className='form-control' placeholder='Mobile' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Aadhar N.</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='aadhar' value={ userdata.aadhar } className='form-control' placeholder='Aadhar Number' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Pan N.</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='pannumber' value={ userdata.pannumber } className='form-control' placeholder='Pan Number' />
                                </div>
                            </div>
                            <div className="row my-1">
                                <div className="col-md-4">
                                    <p>Address</p>
                                </div>
                                <div className="col-md-6">
                                    <textarea type="text" onChange={ (e) => changeHandler(e) } name='address' value={ userdata.address } className='form-control' placeholder='Address' rows={ '5' } />
                                </div>
                            </div>
                            <center className='my-5'>
                                <button className="btn btn-primary">Update</button>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => ({
    profile: state?.getUserProfile?.data
})
export default connect(mapPropsToState, { getUserProfile, updateProfile })(Profile)