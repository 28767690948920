import { ADMIN_LOGIN, ADMIN_LOGOUT, GET_ADMIN_PAYMENTS, GET_BACKOFFICE_PASS_USER, GET_BANK_ADMIN, GET_CODES_ADMIN, GET_NEW_FOR_USER, GET_PAN_ADMIN, GET_PAN_BY_USERID_ADMIN, GET_USERS, GET_USER_PAYMENTS_ADMIN, GET_USER_PAYMENT_HISTORY_ADMIN, INVELID_AUTH } from "../action/Types";
import { setAuthTokenAdmin } from "../utils/setAuthToken";

const isAuth = {
    isAuthenticated: false,
    token: null,
    users: null
}

const users = null;
const pans = null;
const payments = null;
const codes = null;

/**
 * Login reducer
 */
export const adminAuth = (state = isAuth, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case ADMIN_LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                token: payload
            };
        case ADMIN_LOGOUT:
        case INVELID_AUTH:
            setAuthTokenAdmin();
            return {
                users: null,
                isAuthenticated: false,
                token: null
            };
        default: return state;
    }
}
/**
 * Users reducer
 */
export const getUsers = (state = users, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case GET_USERS:
            return {
                ...state,
                data: payload
            };
        case INVELID_AUTH:
            setAuthTokenAdmin();
            return {
                users: null
            };
        default: return state;
    }
}

/**
 * 
 * @param {*} state initial state
 * @param {*} action Dispatch function and return data
 * @returns Get data for admin res as json
 */
export const getPansAdmin = (state = pans, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case GET_PAN_ADMIN:
            return {
                ...state,
                data: payload
            };
        case GET_PAN_BY_USERID_ADMIN:
            return {
                ...state,
                userPans: payload
            };
        case INVELID_AUTH:
            setAuthTokenAdmin();
            return {
                users: null
            };
        default: return state;
    }
}

/**
 * 
 * @param {*} state initial state
 * @param {*} action Dispatch function and return data
 * @returns Get data for admin res as json
 */
export const getPaymentAdmin = (state = payments, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case GET_ADMIN_PAYMENTS:
            return {
                ...state,
                data: payload
            };
        case GET_USER_PAYMENTS_ADMIN:
            return {
                ...state,
                paymentByUserId: payload
            };
        case GET_USER_PAYMENT_HISTORY_ADMIN:
            return {
                ...state,
                paymentHistory: payload
            };
        case INVELID_AUTH:
            setAuthTokenAdmin();
            return {
                users: null
            };
        default: return state;
    }
}

/**
 * 
 * @param {*} state initial state
 * @param {*} action Dispatch function and return data
 * @returns Get data for admin res as json
 */
export const getCodesAdmin = (state = codes, action) => {
    const { type, payload } = action;
    // console.log(payload);
    switch (type) {
        case GET_CODES_ADMIN:
            return {
                ...state,
                data: payload
            };
        case GET_BACKOFFICE_PASS_USER:
            return {
                ...state,
                userPass: payload
            };
        case GET_BANK_ADMIN:
            return {
                ...state,
                bankdata: payload
            };
        case GET_NEW_FOR_USER:
            return {
                ...state,
                news: payload
            };
        case INVELID_AUTH:
            setAuthTokenAdmin();
            return {
                users: null
            };
        default: return state;
    }
}
