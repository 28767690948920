import React from 'react';
import { useFormik } from 'formik';
import { loginValidation } from '../../../validation/validation';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { login } from '../../../action/Index';

const Login = ({ login }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      mobile: '',
      password: ''
    },
    loginValidation,
    onSubmit: values => {
      dispatch(login(values, navigate))
    },
  });
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <form onSubmit={ formik.handleSubmit } autoComplete='off' className='p-5 border rounded-3 bg-light'>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputmobile1"
                  className="form-label">
                  Mobile address
                </label>
                <input type="mobile"
                  className="form-control"
                  autoComplete='off'
                  id="exampleInputmobile1"
                  name='mobile'
                  onChange={ formik.handleChange }
                  value={ formik.values.mobile }
                  aria-describedby="mobileHelp" />
                { formik?.errors?.mobile ? <small className='form-text text-danger'>{ formik?.errors?.mobile }</small> : null }
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete='off'
                  name='password'
                  onChange={ formik.handleChange }
                  value={ formik.values.password }
                  id="exampleInputPassword1" />
                { formik?.errors?.password ? <small className='form-text text-danger'>{ formik?.errors?.password }</small> : null }
              </div>
              <button type="submit" className="btn btn-primary rounded-pill coll-8">Login</button>
              <Link to={ '/signup' } className="m-2 coll-4">Sign Up</Link>
              <Link to={ '/forgotpassword' }>Forgot Password</Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { login })(Login)