import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserPaymentHistoryAdmin } from '../../../action/admin/Index';
import moment from 'moment';

const UserPaymentHistory = ({ getUserPaymentHistoryAdmin, paymentsdata }) => {
    const [limit, setLimit] = useState(100);
    const [skip, setSkip] = useState(0);
    const [filter, setFilter] = useState('');
    const [paymentid, setpaymentid] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    // console.log(paymentsdata)
    useEffect(() => {
        getUserPaymentHistoryAdmin(state?.paymentBy, limit, skip, filter, navigate)
    }, [limit, filter])

    const scrollToEnd = () => {
        setLimit(limit + skip)
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }
    return (
        <div className="container-fluid my-5">
            <div className="row mx-3">
                <div className="card mt-5">
                    <div className="card-header">
                        <h4 className="text-center"><span className='badge bg-primary'>{ state?.username?.replace(/_/g, ' ') }</span> Payments History</h4>
                    </div>
                    <div className="card-body">
                        <div className='mb-3'>
                        </div>
                        <table className="table table-responsive table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">SR.</th>
                                    <th scope="col">Payment Id</th>
                                    <th scope="col">Date of apply</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Tatal Amount</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    paymentsdata?.map((data, i) => (
                                        <tr key={ i }>
                                            <th scope="row">{ i + 1 }</th>
                                            <td>{ data.forgianId }</td>
                                            <td>{ moment(data.date).format('lll') }</td>
                                            <td>{ data.amount }</td>
                                            <td>{ data.wallet }</td>
                                            <td>{ data.status }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => ({
    paymentsdata: state?.getPaymentAdmin?.paymentHistory
})
export default connect(mapPropsToState, { getUserPaymentHistoryAdmin })(UserPaymentHistory)