import { Route, Routes } from 'react-router-dom';
import CreatePassword from './components/users/auth/CreatePassword';
import ForgotPassword from './components/users/auth/ForgotPassword';
import Login from './components/users/auth/Login';
import SignUp from './components/users/auth/SignUp';
import Dashboard from './components/users/dashboard/Dashboard';
import Profile from './components/users/dashboard/Profile';
import NewPan from './components/users/pans/NewPan';
import Pans from './components/users/pans/Pans';
import AddPayments from './components/users/payments/AddPayments';
import History from './components/users/payments/History';
import Payments from './components/users/payments/Payments';
import AdminLogin from './components/admin/auth/Login';
import Users from './components/admin/dashboard/Users';
import UsersProfile from './components/admin/dashboard/UsersProfile';
import AdminPans from './components/admin/pans/Pans';
import PanUpdate from './components/admin/pans/PanUpdate';
import PaymentsAdmin from './components/admin/payments/Payments';
import Payment from './components/admin/payments/Payment';
import UserPans from './components/admin/pans/UserPans';
import UserPayments from './components/admin/payments/UserPayments';
import UserPaymentHistory from './components/admin/payments/UserPaymentHistory';
import AdminCodes from './components/admin/codes/Codes';
import Setting from './components/admin/setting/Setting';
import Home from './components/Home';

function Routing() {
  return (
    <Routes>
      <Route path='/' element={ <Home /> } />
      <Route path='/login' element={ <Login /> } />
      <Route path='/signup' element={ <SignUp /> } />
      <Route path='/forgotpassword' element={ <ForgotPassword /> } />
      <Route path='/create/password/:token' element={ <CreatePassword /> } />
      <Route path='/dashboard' element={ <Dashboard /> } />
      <Route path='/profile' element={ <Profile /> } />
      <Route path='/newpan' element={ <NewPan /> } />
      <Route path='/pans' element={ <Pans /> } />
      <Route path='/panupdate' element={ <NewPan /> } />
      <Route path='/payments' element={ <Payments /> } />
      <Route path='/addpayments' element={ <AddPayments /> } />
      <Route path='/history' element={ <History /> } />
      <Route path='/admin' element={ <AdminLogin /> } />
      <Route path='/admin/dashboard' element={ <Users /> } />
      <Route path='/admin/user/profile' element={ <UsersProfile /> } />
      <Route path='/admin/pans' element={ <AdminPans /> } />
      <Route path='/admin/panupdate' element={ <PanUpdate /> } />
      <Route path='/admin/userpans' element={ <UserPans /> } />
      <Route path='/admin/payments' element={ <PaymentsAdmin /> } />
      <Route path='/admin/userpayments' element={ <UserPayments /> } />
      <Route path='/admin/payment' element={ <Payment /> } />
      <Route path='/admin/userpaymenthistory' element={ <UserPaymentHistory /> } />
      <Route path='/admin/codes' element={ <AdminCodes /> } />
      <Route path='/admin/setting' element={ <Setting /> } />
    </Routes>
  );
}

export default Routing;
