import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getUserProfile } from '../../../action/Index';
import { getNew } from '../../../action/admin/Index';

const Home = ({ getUserProfile, data, getNew, news }) => {
    const navigate = useNavigate();
    useEffect(() => {
        getUserProfile(navigate);
        getNew(navigate);
    }, [])

    const classes = {
        card: {
            background: '#1F3A5D 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            height: '100px',
            // borderRadius: '50%',
            // width: '50%'
        },
        icon1: {
            width: 'auto',
            height: '64px',
            background: '#6861CE 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: '1',
            fontSize: '35px'
        },
        icon2: {
            width: 'auto',
            height: '64px',
            background: '#F684EF 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: '1',
            fontSize: '35px'
        },
        icon3: {
            width: 'auto',
            height: '64px',
            background: '#739713 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: '1',
            fontSize: '35px'
        },
        icon4: {
            width: 'auto',
            height: '64px',
            background: '#48ABF7 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            opacity: '1',
            fontSize: '35px'
        },
        small: {
            fontSize: '12px'
        }
    }
    return (
        <div className='mt-5'>
            <div className="container-fluid">
                <div className="row mx-5">
                    <div className="mt-5">
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon3"><b>News & Events</b></span>
                            <marquee className="form-control rounded-0 p-1" direction="left">
                                { news?.news }
                            </marquee>
                        </div>
                    </div>
                    <div className="my-3">
                        <div className="row text-light">
                            <Link className='btn text-light col-md-12 col-xs-12 col-sm-4 col-xl-6 col-lg-6 col-xxl-3 my-1' to='/newpan'>
                                <div className="card-body" style={ classes.card }>
                                    <div className="row" >
                                        <div className="col-9 col-xxl-8 col-sm-10">
                                            Create New Pan
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link className='btn text-light col-md-12 col-xs-12 col-sm-4 col-xl-6 col-lg-6 col-xxl-3 my-1' to='#'>
                                <div className="card-body" style={ classes.card }>
                                    <div className="row" >
                                        <div className="col-9 col-xxl-8 col-sm-10">
                                            AADHAR Enabled Payment System
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link className='btn text-light col-md-12 col-xs-12 col-sm-4 col-xl-6 col-lg-6 col-xxl-3 my-1' to='#'>
                                <div className="card-body" style={ classes.card }>
                                    <div className="row" >
                                        <div className="col-9 col-xxl-8 col-sm-10">
                                            MONEY TRANSFER
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <Link className='btn text-light col-md-12 col-xs-12 col-sm-4 col-xl-6 col-lg-6 col-xxl-3 my-1' to='#'>
                                <div className="card-body" style={ classes.card }>
                                    <div className="row" >
                                        <div className="col-9 col-xxl-8 col-sm-10">
                                            RECHARGE Prepaid/Postpaid/DTH
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <a className='btn text-light col-md-12 col-xs-12 col-sm-4 col-xl-6 col-lg-6 col-xxl-3 my-1' href='https://pan.vertexnsdl.in/Login.aspx' target={ '_blank' }>
                                <div className="card-body" style={ classes.card }>
                                    <div className="row" >
                                        <div className="col-9 col-xxl-8 col-sm-10">
                                            Paperless Pancard Apply
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    data: state?.getUserProfile?.data,
    news: state?.getCodesAdmin?.news
})
export default connect(mapStateToProps, { getUserProfile, getNew })(Home)