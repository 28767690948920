import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBackOfficeUserPass, updateBackOfficeUserPass } from '../../../action/admin/Index';
import Bank from './Bank';

const Setting = ({ getBackOfficeUserPass, userPass, updateBackOfficeUserPass }) => {
    const navigate = useNavigate();

    const [userpass, setUserpass] = useState({
        id: userPass?._id,
        username1: userPass?.username1 || '',
        username2: userPass?.username2 || '',
        username3: userPass?.username3 || '',
        password1: userPass?.password1 || '',
        password2: userPass?.password2 || '',
        password3: userPass?.password3 || '',
        panManulVerify: userPass?.panManulVerify || '',
        news: userPass?.news || ''
    });

    // console.log('userpass', userPass);

    const updateUserPass = async (e) => {
        e.preventDefault();
        updateBackOfficeUserPass(userpass, navigate)
    }

    const changeHandler = (e) => {
        setUserpass({ ...userpass, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        getBackOfficeUserPass(navigate);
    }, [])

    return (
        <div className='mt-5'>
            <div className="container-fluid">
                <div className="row mx-3 mt-5">
                    <div className="card mt-5">
                        <div className="card-header">
                            <h4>Setting</h4>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card shadow-sm">
                                        <div className="card-header">
                                            <h4 className="text-center">Add user password of Backoffice</h4>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={ updateUserPass }>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Username1</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='username1' onChange={ (e) => changeHandler(e) } value={ userpass.username1 } placeholder='Username 1' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Password1</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='password1' onChange={ (e) => changeHandler(e) } value={ userpass.password1 } placeholder='password 1' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Username2</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='username2' onChange={ (e) => changeHandler(e) } value={ userpass.username2 } placeholder='Username 2' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Password2</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='password2' onChange={ (e) => changeHandler(e) } value={ userpass.password2 } placeholder='password 2' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Username3</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='username3' onChange={ (e) => changeHandler(e) } value={ userpass.username3 } placeholder='Username 3' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Password3</label>
                                                    <div className="col-sm-6">
                                                        <input type="text" className='form-control rounded-0' name='password3' onChange={ (e) => changeHandler(e) } value={ userpass.password3 } placeholder='password 3' />
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">Pan Manual Verify</label>
                                                    <div className="col-sm-6">
                                                        <select className="form-select rounded-0" name='panManulVerify' onChange={ (e) => changeHandler(e) } value={ userpass.panManulVerify }>
                                                            <option value="NO">No</option>
                                                            <option value="YES">Yes</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mt-1 row">
                                                    <label htmlFor="cardholder" className="col-sm-6 col-form-label">News for Retailers</label>
                                                    <div className="col-sm-6">
                                                        <textarea type="text" rows={ '5' } className='form-control' name='news' onChange={ (e) => changeHandler(e) } value={ userpass.news } placeholder='News' />
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                    <button className="btn btn-dark mt-3">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <Bank />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    userPass: state?.getCodesAdmin?.userPass
});
export default connect(mapStateToProps, { getBackOfficeUserPass, updateBackOfficeUserPass })(Setting)