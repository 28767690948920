import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../action/Index';
import { Adminlogout } from '../../action/admin/Index';


const Header = ({ logout, Adminlogout, userdata }) => {
    // console.log(userdata)
    const navigate = useNavigate();
    const userManu = (
        <div className="navbar-nav mx-5">
            <div className="row">
                <div className="col-md-6">
                    <p className="text-dark ml-5">Wallet:{ userdata?.wallet }</p>
                </div>
                <div className="col-md-6">
                    <div className="dropdown" style={ { float: 'right' } }>
                        <button className="dropbtn">Menu</button>
                        <div className="dropdown-content">
                            <Link className='dropdown-item' to={ '/dashboard' } >Dashboard</Link>
                            <Link className='dropdown-item' to={ '/profile' } >My Profile</Link>
                            <Link className='dropdown-item' to={ '/pans' }>Pans</Link>
                            <Link className='dropdown-item' to={ '/newpan' }>New Pan</Link>
                            <Link className='dropdown-item' to={ '/payments' }>Payments Status</Link>
                            <Link className='dropdown-item' to={ '/addpayments' }>Make a Payments</Link>
                            <Link className='dropdown-item' to={ '/history' }>Payments History</Link>
                            <Link className='dropdown-item text-dark' to={ '#' } onClick={ () => logout(navigate) } >
                                Sign out
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    const adminManu = (
        <div className="navbar-nav mx-5">
            <div className="dropdown" style={ { float: 'right' } }>
                <button className="dropbtn">Menu</button>
                <div className="dropdown-content">
                    <Link className='dropdown-item' to={ '/admin/dashboard' } >Home</Link>
                    <Link className='dropdown-item' to={ '/admin/pans' }>Pans</Link>
                    <Link className='dropdown-item' to={ '/admin/payments' } >Payment & Ledger</Link>
                    <Link className='dropdown-item' to={ '/admin/setting' }>Setting</Link>
                    <Link className='dropdown-item' to={ '/admin/codes' }>Codes</Link>
                    <Link className='dropdown-item text-dark' to={ '#' } onClick={ () => Adminlogout(navigate) } >
                        Sign out
                    </Link>
                </div>
            </div>
        </div>
    )
    return (
        <div>
            <header style={ { /*background: '#0E2644 !important',*/ boxShadow: '0px 4px 10px #2667801A' } } className="navbar bg-light navbar-light fixed-top flex-md-nowrap p-3 shadow">
                <a className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
                    { window.location.hostname.toLocaleUpperCase() }
                </a>
                <button className="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                { localStorage.getItem('token') ? <div className="text-light" style={ { marginRight: '50%' } }>
                    <h6>Welcome { userdata?.fullname }</h6>
                </div> : <div className="text-light float-end">
                    <Link className='nav-link' to={ '/login' }>Login</Link>
                </div> }
                { localStorage.getItem('token') ? userManu : localStorage.getItem('tokens') ? adminManu : null }
            </header>
        </div>
    )
}

const mapPropsToState = state => ({
    userdata: state?.getUserProfile?.data
})

export default connect(mapPropsToState, { logout, Adminlogout })(Header)