import React, { useEffect } from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getBank, updateBank } from '../../../action/admin/Index';


const Bank = ({ getBank, bankdata, updateBank }) => {
    const navigate = useNavigate();
    const [bankDetails, setBankDetails] = useState({
        id: bankdata?._id,
        bankname: bankdata?.bankname,
        ifsccode: bankdata?.ifsccode,
        accountnumber: bankdata?.accountnumber
    })
    // console.log('bankdata', bankdata)
    const changeHandle = (e) => {
        setBankDetails({ ...bankDetails, [e.target.name]: e.target.value })
    }

    const addBank = async (e) => {
        e.preventDefault();
        updateBank(bankDetails, navigate);
    }

    useEffect(() => {
        getBank('', navigate);
    }, [])


    return (
        <div>
            <div className='row'>
                <div className='col-md-6'></div>
                <div className="card shadow-sm">
                    <div className="card-header bg-dark">
                        <h3 className="text-light">Add bank</h3>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ addBank }>
                            <div>
                                <label className="col-form-label" for="bankname">Bank name</label>
                                <input type="text" className="form-control form-control-sm" id="bankname" onChange={ (e) => changeHandle(e) } value={ bankDetails.bankname } name="bankname" />
                            </div>
                            <div>
                                <label for="ifsccode" className="form-label">Bank Ifsc Code</label>
                                <input type="text" name="ifsccode" value={ bankDetails.ifsccode } onChange={ (e) => changeHandle(e) } className="form-control form-control-sm" id="ifsccode" />
                            </div>
                            <div>
                                <label for="accountnumber" className="form-label">Bank Account number</label>
                                <input type="text" name="accountnumber" value={ bankDetails.accountnumber } onChange={ (e) => changeHandle(e) } className="form-control form-control-sm" id="accountnumber" />
                            </div>
                            <button type="submit" className="btn btn-dark mt-3">Add Bank</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    bankdata: state?.getCodesAdmin?.bankdata
})
export default connect(mapStateToProps, { getBank, updateBank })(Bank)