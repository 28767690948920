import { combineReducers } from 'redux';
import { ADMIN_LOGOUT, INVELID_AUTH, LOG_OUT } from '../action/Types';
import { loginReducer, getCodesForUser, getUserPans, getBankForUser, getUserPayments, getUserPaymentsHistory, getUserProfile, loader } from './Reducers';
import { adminAuth, getUsers, getPansAdmin, getPaymentAdmin, getCodesAdmin } from './adminReducers';


const appReducer = combineReducers({
    loginReducer,
    getCodesForUser,
    getUserPans,
    getBankForUser,
    getUserPayments,
    getUserPaymentsHistory,
    getUserProfile,
    adminAuth,
    getUsers,
    getPansAdmin,
    getPaymentAdmin,
    getCodesAdmin,
    loader
})

// reset the state of a redux store
const rootReducer = (state, action) => {
    if (action.type === LOG_OUT || action.type === INVELID_AUTH || action.type === ADMIN_LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action)
}

export default rootReducer;