import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAdminBank, insertPaymentRequest } from '../../../action/Index';

const AddPayments = ({ getAdminBank, bankdetials, insertPaymentRequest }) => {
    const [payment, setPayment] = useState({
        bankName: '',
        tranferRefCode: '',
        amount: '',
    });
    const [loader, setLoader] = useState(false);
    const [bank, setBank] = useState();
    const navigate = useNavigate();

    const changeHandler = (e) => {
        setPayment({ ...payment, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        getAdminBank(navigate)
    }, [])
    const submit = async (e) => {
        e.preventDefault();
        insertPaymentRequest(payment, navigate)
    }
    return (
        <div className="container-fluid my-5">
            <div className="row mx-5">
                <div className="card mt-5">
                    <div className="card-header">
                        <h4 className='text-center mb-5'>Add Payment Request</h4>
                        <div className="row">
                            <div className="col-md-6">
                                <p>Bank Name</p>
                            </div>
                            <div className="col-md-6">
                                <p>{ bankdetials?.bankname }</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <p>IFSC Code</p>
                            </div>
                            <div className="col-md-6">
                                <p>{ bankdetials?.ifsccode }</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <p>Account Number</p>
                            </div>
                            <div className="col-md-6">
                                <p>{ bankdetials?.accountnumber }</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={ submit }>
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Bank Name</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" onChange={ (e) => changeHandler(e) } name='bankName' value={ payment.bankName } className='form-control' placeholder='Bank Name' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Payment Reference No.</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className='form-control' onChange={ (e) => changeHandler(e) } name='tranferRefCode' value={ payment.tranferRefCode } placeholder='Bank Name' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p>Amount (Rs.)</p>
                                </div>
                                <div className="col-md-6">
                                    <input type="text" className='form-control' onChange={ (e) => changeHandler(e) } name='amount' value={ payment.amount } placeholder='Bank Name' />
                                </div>
                            </div>
                            <center className='my-5'>
                                <button className="btn btn-primary">Submit</button>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => ({
    bankdetials: state?.getBankForUser?.data
})
export default connect(mapPropsToState, { getAdminBank, insertPaymentRequest })(AddPayments)