import { GET_BANK_FOR_USER, GET_CODES_USER, GET_USER_PANS, GET_USER_PAYMENTS, GET_USER_PAYMENTS_HISTORY, GET_USER_PROFILE, INVELID_AUTH, LOADER, LOGIN, LOG_OUT } from "../action/Types";
import { setAuthToken } from "../utils/setAuthToken";



const isAuth = {
    users: null,
    isAuthenticated: null,
    token: null,
}
const pans = null;
const bankdata = null;
const payments = null;
const paymentsHistory = null;
const userprofile = null;
const loaderInitial = false;

export const loader = (state = loaderInitial, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case LOADER:
            return {
                ...state, data: payload,
            };
        default: return state;
    }
}

/**
 * Login reducer
 */
export const loginReducer = (state = isAuth, action) => {
    const { type, payload } = action;
    // console.log(payload?.userDetail);
    switch (type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                token: payload
            };
        case LOG_OUT:
        case INVELID_AUTH:
            setAuthToken();
            return {
                users: null,
                isAuthenticated: false,
                token: null
            };
        default: return state;
    }
}


/**
 * Login reducer
 */
export const getCodesForUser = (state = isAuth, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CODES_USER:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}


/**
 * @Get user Pans
 */
export const getUserPans = (state = pans, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_PANS:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}


/**
 * @Get Bank details for user
 */
export const getBankForUser = (state = bankdata, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_BANK_FOR_USER:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}


/**
 * @Get Bank details for user
 */
export const getUserPayments = (state = payments, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_PAYMENTS:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}


/**
 * @Get Bank details for user
 */
export const getUserPaymentsHistory = (state = paymentsHistory, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_PAYMENTS_HISTORY:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}


/**
 * @Get Bank details for user
 */
export const getUserProfile = (state = userprofile, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_PROFILE:
            return {
                ...state,
                data: payload
            };
        default: return state;
    }
}
