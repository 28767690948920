/**
 * @Register api
 */
export const REGISTER_API = '/public/register';
/**
 * Login api
 */
export const LOGIN_API = '/public/login';

/**
 * @Forgot password
 */
export const FORGOT_PASSWORD_API = '/public/reset-password';


/**
 * @Forgot password
 */
export const CREATE_PASSWORD_API = '/public/new-password';

