import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from './components/loader/Loader';
import Header from './components/navbar/Header';
import Sidebar from './components/navbar/Sidebar';
import Routing from './Routing';
import { setAuthToken, setAuthTokenAdmin } from './utils/setAuthToken';

function App({ userdat, loader }) {

  useEffect(() => {
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    if (localStorage.tokens) {
      setAuthTokenAdmin(localStorage.tokens)
    }
  }, [])


  return (
    <div>
      <Header />
      {
        loader == undefined || loader == '' || loader == false ? null : <Loader />
      }
      <div className="mt-5">
        <Routing />
      </div>
    </div >
  );
}

const mapStateToProps = (state) => ({
  userdat: state.loginReducer,
  loader: state?.loader?.data
})
export default connect(mapStateToProps, {})(App);
