import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPanByUserIdAdmin } from '../../../action/admin/Index';
import moment from 'moment';
import SlipUpload from './SlipUpload';

const UserPans = ({ getPanByUserIdAdmin, pans }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({
        panId: 0,
        limit: 100
    })
    const [skip, setSkip] = useState(0);
    const [filter, setFilter] = useState();
    const { state } = useLocation();
    // console.log(pans)

    const scrollToEnd = () => {
        setData({ limit: data.limit + skip })
    }
    window.onscroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
            scrollToEnd()
        }
    }



    const handlechange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const sumbit = (e) => {
        e.preventDefault();
        getPanByUserIdAdmin(state?.user, data.limit, skip, filter, data.panId, navigate);
    }
    useEffect(() => {
        getPanByUserIdAdmin(state?.user, data.limit, skip, filter, data.panId, navigate);
    }, [])

    return (
        <div className='my-5 container-fluid'>
            <div className="row mx-3">
                <div className="card mt-5">
                    <div className="card-header">
                        <h5><span className='badge bg-primary'>{ state?.username?.replace(/_/g, ' ') }</span> Pans Records</h5>
                    </div>
                    <div className="card-body">
                        <div className='mb-3'>
                            <form onSubmit={ sumbit }>
                                <div className="row">
                                    <div className="col-md-3">
                                        <select name="filter" className='form-select' id="filter" onChange={ (e) => setFilter(e.target.value) } value={ filter }>
                                            <option>-Select-</option>
                                            <option value="PANDING">PENDING</option>
                                            <option value="VERIFY">VERIFY</option>
                                            <option value="REUPLOAD">REUPLOAD</option>
                                            <option value="REJECTED">REJECTED</option>
                                            <option value="COMPLETE">COMPLETE</option>
                                            <option value="PANID">PAN ID</option>
                                            <option value="ACK">ACKNOWLEDGEMENT</option>
                                        </select>
                                    </div>
                                    { filter == 'PANID' || filter == 'ACK' ? <div className="col-md-3">
                                        <input type="number" value={ data.panId } className='form-control' onChange={ (e) => handlechange(e) } name="panId" id="panId" placeholder='Pan or Acknowledgement' />
                                    </div> : null }
                                    <div className="col-md-3">
                                        <input type="number" value={ data.limit } className='form-control' onChange={ (e) => handlechange(e) } name="limit" id="limit" placeholder='limit' />
                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-dark">Search</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th scope="col">S.R.</th>
                                    <th scope="col">Pan Id</th>
                                    <th scope="col">Date Applied</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Document</th>
                                    <th scope="col">Acknowledgement No</th>
                                    <th scope="col">Reason</th>
                                    <th scope="col">Slip Upload</th>
                                </tr>
                            </thead>
                            <tbody>
                                { pans?.map((pan, i) => (
                                    <tr key={ i }>
                                        <th scope="row">{ i + 1 }</th>
                                        <td>
                                            <Link to={ `/admin/panupdate` } state={ pan } >{ pan.forgianId }</Link>
                                        </td>
                                        <td>{ moment(pan.date).format('ll') }</td>
                                        { pan.panStatus == false && !pan.reasonOfReject ?
                                            <td className='text-light bg-secondary'> Pending </td>
                                            : pan.panStatus == true && !pan.reasonOfReject ?
                                                <td className='text-light bg-success'> Verify </td>
                                                : pan.panStatus == false && pan.reasonOfReject ?
                                                    <td className='text-light bg-danger'> { pan.reasonOfReject } </td>
                                                    : pan.panStatus == true && !pan.reasonOfReject && pan.acknowledgement ?
                                                        <td className='text-light bg-success'> Completed </td>
                                                        : pan.panStatus == true && pan.reasonOfReject == 'REUPLOAD' ?
                                                            <td className='text-light bg-warning'>Reupload </td> : null
                                        }
                                        <td>
                                            <Link target={ '_blank' } to={ `/panfiles/${pan.image}` }>Image</Link> &nbsp;&nbsp;
                                            <Link target={ '_blank' } to={ `/panfiles/${pan.sig}` }>Sig</Link>&nbsp;&nbsp;
                                            <Link target={ '_blank' } to={ `/panfiles/${pan.pdf}` }>PDF</Link>&nbsp;&nbsp;
                                        </td>
                                        <td>
                                            <Link className='nav-link' to={ `/fornsdl/${pan?.slip}` }>{ pan?.acknowledgement }</Link>
                                        </td>
                                        <td>{ pan.reasonOfReject }</td>
                                        <td>
                                            <div>
                                                <SlipUpload pandata={ pan } />
                                            </div>
                                        </td>
                                    </tr>
                                )) }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapPropsToState = (state) => ({
    pans: state?.getPansAdmin?.userPans
})

export default connect(mapPropsToState, { getPanByUserIdAdmin })(UserPans)