// A custom validation function. This must return an object
// which keys are symmetrical to our values/initialValues
export const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.fullname) {
    errors.fullname = 'Required';
  } else if (values.fullname.length < 3) {
    errors.fullname = 'Full name must be at least 3 characters';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be at least 5 characters';
  }
  if (!values.mobile) {
    errors.mobile = 'Required';
  } else if (values.mobile.length < 10) {
    errors.mobile = 'Mobile must be at least 10 characters';
  }

  return errors;
};


/**
 * Validataion for login
 */
export const loginValidation = values => {
  const errors = {};
  if (!values.mobile) {
    errors.mobile = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.mobile)) {
    errors.mobile = 'Invalid mobile address';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be at least 5 characters';
  }

  return errors;
}

/**
 * 
 * @param {*} values string
 * @returns Msg and value
 */
export const adminLoginValidation = values => {
  const errors = {};
  if (!values.username) {
    errors.username = 'Required';
  } else if (!values.username) {
    errors.username = 'Invalid mobile address';
  }
  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 5) {
    errors.password = 'Password must be at least 5 characters';
  }

  return errors;
}