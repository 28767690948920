import { api, apiAdmin } from "./api";

/**
 * 
 * @param login token and used every api  
 */
export const setAuthToken = (token) => {
    if (token) {
        api.defaults.headers.common['x-auth-token'] = token;
        localStorage.setItem('token', token);
    } else {
        delete api.defaults.headers.common['x-auth-token'];
        localStorage.removeItem('token');
    }
};


/**
 * 
 * @param {*} tokens for admin authentication
 */
export const setAuthTokenAdmin = (token) => {
    if (token) {
        apiAdmin.defaults.headers.common['x-adminAuth-token'] = token;
        localStorage.setItem('tokens', token);
    } else {
        delete apiAdmin.defaults.headers.common['x-adminAuth-token'];
        localStorage.removeItem('tokens');
    }
};

