export const RESISTER = 'RESISTER';
export const LOGIN = 'LOGIN';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_CODES_USER = 'GET_CODES_USER';
export const LOADER = 'LOADER';
export const GET_USER_PANS = 'GET_USER_PANS';
export const GET_BANK_FOR_USER = 'GET_BANK_FOR_USER';
export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS';
export const GET_USER_PAYMENTS_HISTORY = 'GET_USER_PAYMENTS_HISTORY';
export const LOG_OUT = 'LOG_OUT';
export const INVELID_AUTH = 'INVELID_AUTH';
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const GET_USERS = 'GET_USERS';
export const ADMIN_LOGOUT = 'ADMIN_LOGOUT';
export const GET_PAN_ADMIN = 'GET_PAN_ADMIN'
export const GET_ADMIN_PAYMENTS = 'GET_ADMIN_PAYMENTS';
export const GET_USER_PAYMENTS_ADMIN = 'GET_USER_PAYMENTS_ADMIN';
export const GET_PAN_BY_USERID_ADMIN = 'GET_PAN_BY_USERID_ADMIN';
export const GET_USER_PAYMENT_HISTORY_ADMIN = 'GET_USER_PAYMENT_HISTORY_ADMIN';
export const GET_CODES_ADMIN = 'GET_CODES_ADMIN'; 
export const GET_BACKOFFICE_PASS_USER = 'GET_BACKOFFICE_PASS_USER'; 
export const GET_BANK_ADMIN = 'GET_BANK_ADMIN'; 
export const GET_NEW_FOR_USER = 'GET_NEW_FOR_USER'; 