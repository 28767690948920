import React, { useState } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { slipUPloadByAdmin } from '../../../action/admin/Index';

const SlipUpload = ({ slipUPloadByAdmin, pandata }) => {
    const navigate = useNavigate();
    const [slip, setSlip] = useState({
        slip: '',
        id: pandata?._id,
        slipname: 'Choose Image file',
        msg: []
    })

    const changeSlip = (e) => {
        setSlip({ ...slip, slip: e.target.files[0], slipname: e.target.files[0].name });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        alert('Hi')
        if (slip.slip == '') {
            alert('Please select slip');
            return false
        }
        slipUPloadByAdmin(slip, navigate, e)
    }
    return (
        <div>
            <form onSubmit={ (e) => onSubmit(e) }>
                <div className="form-group mt-3">
                    <div class="input-group">
                        <input type="file" class="form-control form-control-sm" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" name='slip' onChange={ (e) => changeSlip(e) } />
                        <button class="btn btn-outline-secondary btn-sm">Upload</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = (state) => ({

})
export default connect(mapStateToProps, { slipUPloadByAdmin })(SlipUpload)