import { toast } from 'react-toastify';
import { BASE_URL, CREATE_PASSWORD_API, FORGOT_PASSWORD_API, LOGIN_API, REGISTER_API } from '../Services/APIS';
import { api } from '../utils/api';
import { GET_BANK_FOR_USER, GET_CODES_USER, GET_USER_PANS, GET_USER_PAYMENTS, GET_USER_PAYMENTS_HISTORY, GET_USER_PROFILE, LOADER, LOGIN, LOG_OUT, RESISTER } from './Types';


/**
 * @description This is for notification
 * @param {*} payload boolean
 * @returns 
 */
export const loader = (payload) => async (dispatch) => {
    dispatch({
        type: LOADER,
        payload: payload
    })
}

/**
 * Register
 */
export const register = (e, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const userdata = {
            fullname: e.fullname,
            email: e.email,
            password: e.password,
            mobile: e.mobile
        }
        const res = await api.post(`${REGISTER_API}`, userdata)
        if (res.status == 200) {
            toast.success('Register sucess full!')
            dispatch(loader(false));
            navigate('/')
        }
    } catch (error) {
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
    }
}


/**
 * Login 
 */
export const login = (e, navigate) => async (dispatch) => {
    // console.log(e);
    try {
        dispatch(loader(true));
        const logindata = {
            mobile: e.mobile,
            password: e.password
        }
        const res = await api.post(`${LOGIN_API}`, logindata);
        // console.log(res.data);
        if (res.status == 200) {
            navigate('/dashboard')
            toast.success(res.data.msg[0].msg)
            dispatch({
                type: LOGIN,
                payload: res.data.token
            })
            dispatch(loader(false));
        }
    } catch (error) {
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
    }
}

/**
 * This for get user profile or user data
 * @param {*} navigate function
 * @returns json
 */
export const getUserProfile = (navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await api.get('/public/userProfile');
        // console.log('userdata ', res.data)
        dispatch({
            type: GET_USER_PROFILE,
            payload: res.data
        })
        dispatch(loader(false));
    } catch (error) {
        dispatch(loader(false));
        console.log('error', error)
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/');
        }
    }
}

export const updateProfile = (data, navigate) => async (dispatch) => {
    try {
        // console.log(data)
        const res = await api.put(`/public/profile/update/${data?.id}`, data);
        if (res.status == 200) {
            dispatch({
                type: GET_USER_PROFILE,
                payload: res.data.data
            })
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        console.log('error', error);
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/');
        }
    }
}

/**
 * Login 
 */
export const forgotPassword = (e, navigate) => async (dispatch) => {
    // console.log(e);
    try {
        const data = {
            email: e.email,
        }
        const res = await api.post(`${FORGOT_PASSWORD_API}`, data);
        console.log(res.data);
        if (res.status == 200) {
            navigate('/')
            toast.success(res.data.msg[0].msg)
            // dispatch({
            //     type: LOGIN,
            //     payload: res.data.token
            // })
        }
    } catch (error) {
        toast.error(error?.response?.data?.msg[0]?.msg)
    }
}


/**
 * Login 
 */
export const createNewPassword = (e, navigate) => async (dispatch) => {
    // console.log(e);
    try {
        const data = {
            password: e.password,
            token: e.token
        }
        const res = await api.post(`${CREATE_PASSWORD_API}`, data);
        // console.log(res.data);
        if (res.status == 200) {
            navigate('/')
            toast.success(res.data.msg[0].msg)
        }
    } catch (error) {
        toast.error(error?.response?.data?.msg[0]?.msg)
    }
}


/**
 * @Create New Pan
 */
export const createNewPan = (e, navigate) => async (dispatch) => {
    // console.log('pan data', e)
    // alert("Hi new pan")
    try {
        dispatch(loader(true));
        const formData = new FormData();
        formData.append("id", e?.id);
        formData.append("aocode", e.aocode);
        formData.append('ao', e.ao);
        formData.append('range', e.range);
        formData.append('aonumber', e.aonumber);
        formData.append('application', e.application);
        formData.append('old_pan', e.old_pan);
        formData.append('category', e.category);
        formData.append('firstname', e.firstname);
        formData.append('middlename', e.middlename);
        formData.append('lastname', e.lastname);
        formData.append('ffirstname', e.ffirstname);
        formData.append('fmiddlename', e.fmiddlename);
        formData.append('flastname', e.flastname);
        formData.append('cardHolder', e.cardHolder);
        formData.append('dob', e.dob);
        formData.append('contect_number', e.contect_number);
        formData.append('email', e.email);
        formData.append('proofid', e.proofid);
        formData.append('proofadd', e.proofadd);
        formData.append('proofdob', e.proofdob);
        formData.append('adhar_number', e.adhar_number);
        formData.append('address_f', e.address_f);
        formData.append('address_v', e.address_v);
        formData.append('address_p', e.address_p);
        formData.append('address_divi', e.address_divi);
        formData.append('address_d', e.address_d);
        formData.append('state', e.state);
        formData.append('applicant', e.applicant);
        formData.append('pin_code', e.pin_code);
        formData.append('image', e.image);
        formData.append('pdf', e.pdf);
        formData.append('sig', e.sig)
        if (e.id != "") {
            const updatepan = await api.put('/pans/pan/update', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            // console.log('updatepan', updatepan)
            if (updatepan.status == 200) {
                toast.success(updatepan.data.msg[0].msg)
                // return navigate('/pans')
                dispatch(loader(false));
            }
        } else {
            const res = await api.post('/pans/create', formData)
            if (res.status == 200) {
                toast.success('Pan Created')
                dispatch(loader(false));
                return navigate('/pans')
            }
        }
    } catch (error) {
        console.log(error)
        dispatch(loader(false));
        toast.error(error.response.data.msg[0].msg)
        if (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                return navigate('/');
            }
        }
    }
}

/**
 * @get Codes for user
 */
export const getCodesUser = (navigate) => async (dispatch) => {
    try {
        const res = await api.get(`/codes/get/codes/foruser`);
        if (res.status == 200) {
            dispatch({
                type: GET_CODES_USER,
                payload: res.data
            })
        }
    } catch (error) {
        console.log(error.response);
        if (error?.response?.status == 401) {
            navigate('/', { replace: true });
            localStorage.removeItem('token');
        }
    }
}

/**
 * 
 * @param {*} e data
 * @param {*} navigate for nav
 * @returns Pans data in json
 */
export const getUserPan = (limit, skip, filter, panId, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            limit: limit,
            skip: skip,
            filter: filter,
            panId: panId,
            navigate: navigate
        }
        const res = await api.post(`/pans/myPans/user`, data)
        if (res.status == 200) {
            dispatch({
                type: GET_USER_PANS,
                payload: res.data
            })
            dispatch(loader(false));
        }
    } catch (error) {
        console.log(error.response.data)
        dispatch(loader(false));
        if (error) {
            if (error.response.status == 401) {
                localStorage.removeItem('token');
                return navigate('/');
            }
        }
    }
}

/**
 * 
 * @param {*} e payment data
 * @param {*} navigate for navigatre
 * @returns payment data in json
 */
export const insertPayment = (e, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await api.post('/payments/payment/add', e,)
        if (res.status == 200) {
            dispatch(loader(false));
            toast.success(res.data.msg[0].msg);
            navigate('/paymentstatus')
        }
    } catch (error) {
        console.log('error', error.response.data.msg[0].msg)
        dispatch(loader(false));
        toast.error(error.response.data.msg[0].msg);
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            return navigate('/');
        }
    }
}

/**
 * 
 * @param {*} e req data
 * @param {*} navigate for navigate
 * @returns bank data in json
 */
export const getAdminBank = (navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await api.get('/admin/bank/new/bank');
        // console.log('bank details', res.data);
        if (res.status == 200) {
            dispatch(loader(false));
            dispatch({
                type: GET_BANK_FOR_USER,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/');
        }
    }
}

/**
 * 
 * @param {*} data Json data user Data for insert
 * @param {*} navigate Function for redirect
 * @returns 
 */
export const insertPaymentRequest = (data, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const res = await api.post('/payments/payment/add', data);
        if (res.status == 200) {
            dispatch(loader(false));
            navigate('/payments')
            toast.success(res.data.msg[0].msg);
        }
    } catch (error) {
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/');
        }
    }
}

/**
 * 
 * @param {*} limit Number
 * @param {*} skip Number
 * @param {*} filter String
 * @param {*} navigate function
 * @returns 
 */
export const getUserPayments = (limit, skip, filter, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            limit: limit,
            skip: skip,
            filter: filter,
        }
        const res = await api.post(`/payments/myPayment/user`, data);
        if (res.status == 200) {
            dispatch(loader(false));
            // console.log(res.data);
            dispatch({
                type: GET_USER_PAYMENTS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch(loader(false));
        toast.error(error?.response?.data?.msg[0]?.msg)
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/');
        }
    }
}

/**
 * 
 * @param {*} limit Number
 * @param {*} skip Number
 * @param {*} filter String
 * @param {*} navigate function
 * @returns 
 */
export const getPaymentsHistoryForUser = (limit, skip, filter, navigate) => async (dispatch) => {
    try {
        dispatch(loader(true));
        const data = {
            limit: limit,
            skip: skip,
            filter: filter,
        }
        const res = await api.post(`/history/mypaymenthistory/user`, data);
        dispatch(loader(false));
        if (res.status == 200) {
            // console.log(res.data);
            dispatch({
                type: GET_USER_PAYMENTS_HISTORY,
                payload: res.data
            })
        }
    } catch (error) {
        toast.error(error?.response?.data?.msg[0]?.msg)
        dispatch(loader(false));
        if (error.response.status == 401) {
            localStorage.removeItem('token');
            navigate('/', { replace: true });
        }
    }
}

/**
 * 
 * @param {*} navigate Function
 * @returns logout
 */
export const logout = (navigate) => (dispatch) => {
    setTimeout(() => {
        navigate("/", { replace: true });
    }, 0);
    dispatch({
        type: LOG_OUT
    })
}
