import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Row, Col, Button } from 'react-bootstrap'; // Import Bootstrap components
import About from './About';
import Contact from './Contact';
import Services from './Services';

const Home = () => {
    const navigate = useNavigate();

    return (
        <div>
            {/* Hero Section */ }
            <div
                style={ {
                    backgroundImage: 'url(images/hero_bg_1.jpg)',
                    width: '100%',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    padding: '80px 0px 50px 0px',
                } }
            >
                <div className='container'>
                    <div className="card max-width-345">
                        <div className='row'>
                            <div className='col-xs-12 col-sm-6 col-md-8 col-lg-8'>
                                <div className='card-body'>
                                    {/* <Card.Title as="h4">CSC PAN CARD</Card.Title> */ }
                                    <div>
                                        Welcome to <b>CSC PAN CARD</b> Start Your Own Business with Us.
                                    </div>
                                    <br />
                                    <div>
                                        Join us.
                                        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget
                                        metus. Nullam id dolor id nibh ultricies vehicula ut id elit.
                                    </div>
                                    <br />
                                    <div>
                                        One more for good Services.
                                        Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget
                                        metus. Nullam id dolor id nibh ultricies vehicula ut id elit.
                                    </div>
                                    <div>
                                        <Button className='mt-5' onClick={ () => navigate('/login') } size="sm" variant="primary">
                                            Join Us
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xs-12 col-sm-6 col-md-4 col-lg-4'>
                                <img className='card-img-top' src="images/singh.png" alt="green iguana" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* About Section */ }
            <div
                style={ {
                    backgroundImage: 'url(images/hero_bg_1.jpg)',
                    width: '100%',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                } }
            >
                <div style={ { padding: '50px 0px 50px 0px' } }>
                    <About />
                </div>
            </div>

            {/* Services Section */ }
            <div
                style={ {
                    backgroundImage: 'url(images/hero_bg_1.jpg)',
                    width: '100%',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                } }
            >
                <div style={ { padding: '50px 0px 50px 0px' } }>
                    <Services />
                </div>
            </div>

            {/* Contact Section */ }
            <div
                style={ {
                    backgroundImage: 'url(images/hero_bg_1.jpg)',
                    width: '100%',
                    backgroundAttachment: 'fixed',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                } }
            >
                <div style={ { padding: '50px 0px 50px 0px' } }>
                    <Contact />
                </div>
            </div>

            {/* Copyright */ }
            {/* <center>
        <p>Copyright ©, satendrasingh51.tech</p>
      </center> */}
        </div>
    );
};

export default Home;
