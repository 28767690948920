import React from 'react';
import { useFormik } from 'formik';
import { validate } from '../../../validation/validation';
import { Link, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { register } from '../../../action/Index';

const SignUp = ({ register }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            mobile: '',
            fullname: ''
        },
        validate,
        onSubmit: values => {
            dispatch(register(values, navigate))
        },
    });
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <form onSubmit={ formik.handleSubmit } autoComplete='off' className='p-5 border rounded-3 bg-light'>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label">
                                    Full Name
                                </label>
                                <input type="fullname"
                                    className="form-control"
                                    autoComplete='off'
                                    id="exampleInputEmail1"
                                    name='fullname'
                                    onChange={ formik.handleChange }
                                    value={ formik.values.fullname }
                                    aria-describedby="emailHelp" />
                                { formik?.errors?.fullname ? <small className='form-text text-danger'>{ formik?.errors?.fullname }</small> : null }
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <label
                                        htmlFor="exampleInputEmail1"
                                        className="form-label">
                                        Mobile
                                    </label>
                                    <input type="text"
                                        className="form-control"
                                        autoComplete='off'
                                        id="mobile"
                                        name='mobile'
                                        onChange={ formik.handleChange }
                                        value={ formik.values.mobile }
                                        aria-describedby="emailHelp" />
                                    { formik?.errors?.mobile ? <small className='form-text text-danger'>{ formik?.errors?.mobile }</small> : null }
                                </div>
                                <div className="col-md-6 mb-3">
                                    <label
                                        htmlFor="exampleInputPassword1"
                                        className="form-label">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        autoComplete='off'
                                        name='password'
                                        onChange={ formik.handleChange }
                                        value={ formik.values.password }
                                        id="exampleInputPassword1" />
                                    { formik?.errors?.password ? <small className='form-text text-danger'>{ formik?.errors?.password }</small> : null }
                                </div>
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label">
                                    Email address
                                </label>
                                <input type="email"
                                    className="form-control"
                                    autoComplete='off'
                                    id="exampleInputEmail1"
                                    name='email'
                                    onChange={ formik.handleChange }
                                    value={ formik.values.email }
                                    aria-describedby="emailHelp" />
                                { formik?.errors?.email ? <small className='form-text text-danger'>{ formik?.errors?.email }</small> : null }
                            </div>

                            <button type="submit" className="btn btn-primary rounded-pill">Sign Up</button>
                            <Link to={ '/' } className="m-5">Login</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps, { register })(SignUp) 