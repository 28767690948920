
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getBackOfficeUserPass, getBank, getUsers } from '../../../action/admin/Index';
import moment from 'moment';

const Users = ({ getUsers, data, getBackOfficeUserPass, getBank }) => {
    const navigate = useNavigate();
    // console.log(data);
    useEffect(() => {
        getUsers(navigate);
        getBank('', navigate);
        getBackOfficeUserPass(navigate);
    }, [])

    const getTotal = (e) => {
        var x = document.getElementById('usertable');
        var amount = [0];
        for (let index = 1; index < x?.rows?.length - 1; index++) {
            const tableCells = x?.rows[index].cells;
            // console.log('tableCells[6].innerText', tableCells[5])
            amount.push(parseInt(tableCells[5].innerText));
        }
        const total = amount?.reduce((previouse = 0, current) => parseInt(previouse + current));
        return total;
    }
    return (
        <div>
            <div className="container-fluid mt-5">
                <div className="row mx-5">
                    <div className="card mt-5">
                        <div className="card-header">
                            <h3 className="text-center">List Of Users</h3>
                        </div>
                        <div className="card-body">
                            <table id='usertable' className="table text-center table-responsive table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">SR.</th>
                                        <th scope="col">Retailer Id</th>
                                        <th scope="col">Creation Date</th>
                                        <th scope="col">Retailer Mobile</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Balance (INR)</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.map((user, i) => (
                                            <tr key={ i }>
                                                <th scope="row">{ i + 1 }</th>
                                                <td>{ user.forgianId }</td>
                                                <td>{ moment(user.date).format('ll') }</td>
                                                <td>{ user.mobile }</td>
                                                <td>{ user.fullname }</td>
                                                <td>{ user.wallet }</td>
                                                { user.status ? <td className='text-success'>Active </td> : <td className='text-danger'>Deactivate</td> }
                                                <Link to='/admin/user/profile' state={ user } className='btn text-primary'><td>Manage</td></Link>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td className='bg-success text-light'></td>
                                        <td className='bg-success text-light'></td>
                                        <td className='bg-success text-light'></td>
                                        <td className='bg-success text-light'></td>
                                        <td className='bg-success text-light'>Total Rs.</td>
                                        <td className='bg-success text-light'>{ data?.length > 0 ? getTotal() : null }</td>
                                        <td className='bg-success text-light'></td>
                                        <td className='bg-success text-light'></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    data: state?.getUsers?.data
})

export default connect(mapStateToProps, { getUsers, getBackOfficeUserPass, getBank })(Users)